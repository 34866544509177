// Example Options
// 3x1 = equal to 3 or less
// 6x2 = equal to 12 or less
// 9x3 = equal to 27 or less
// 12x4 = equal to 48 or less
// 15x5 = equal to 75 or less

// Phone wallpaper aspect ratio 16/9

/** Used to calculate the grid dimensions (row x column)
 *  needed for a Twitter header / banner
 *  and also for a phone background
 */
export function calculateGridDimensions(
	numImages,
	ratio = 3,
	landscape = true
) {
	let numRows = landscape ? 1 : ratio;
	let sum = 3;
	let numCols = landscape ? ratio : 1;

	while (sum < numImages) {
		if (landscape) {
			numRows += 1;
			numCols = numRows * ratio;
			sum = numRows * numCols;
		} else {
			numCols += 1;
			numRows = numCols * ratio;
			sum = numRows * numCols;
		}
	}

	return { numRows, numCols };
}
