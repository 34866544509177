import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import React, { useMemo } from "react";
import ReactGA from "react-ga4";

import { ConnectionProvider } from "@solana/wallet-adapter-react";

import { SOLANA_RPC_ENDPOINT } from "./values/constants";
import { MetaplexProvider } from "./components/metaplex-connection/MetaplexProvider";
import { UniversalHeader } from "./components/universal-header/UniversalHeader";
import { WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import {
	PhantomWalletAdapter,
	GlowWalletAdapter,
	SlopeWalletAdapter,
	SolflareWalletAdapter,
	TorusWalletAdapter,
	BackpackWalletAdapter,
	LedgerWalletAdapter,
} from "@solana/wallet-adapter-wallets";

// Default Web3 styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

const GA_TRACKING_ID = "G-NEN3HTRT5F";
ReactGA.initialize(GA_TRACKING_ID);

function App() {
	const network = WalletAdapterNetwork.Mainnet;
	//const endpoint = clusterApiUrl(network); //useMemo(() => clusterApiUrl(network), [network]);
	//const connection = new Connection(clusterApiUrl("devnet"));
	const endpoint = SOLANA_RPC_ENDPOINT.HELIUS;

	const wallets = useMemo(
		() => [
			new PhantomWalletAdapter(),
			new BackpackWalletAdapter(),
			new SlopeWalletAdapter(),
			new GlowWalletAdapter(),
			new SolflareWalletAdapter({ network }),
			new TorusWalletAdapter(),
			new LedgerWalletAdapter(),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[network]
	);

	return (
		<ConnectionProvider endpoint={endpoint}>
			<WalletProvider wallets={wallets} autoConnect>
				<WalletModalProvider>
					<MetaplexProvider>
						<Router>
							<UniversalHeader />
							<AppRoutes />
						</Router>
					</MetaplexProvider>
				</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	);
}

export default App;
