/** 52 Verified NFT Collections by Verified Creator Address */
export const NFT_COLLECTIONS = [
	{
		name: "Monkey Baby Business",
		imageUrl:
			"https://prod-image-cdn.tensor.trade/images/400x400/freeze=false/https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/bafb174c6d4fb01610fd1c9bf2998d9d/29772c78?collectionLogo=true",
		verifiedCreatorAddress: "7JUGhwwoiUQYWtZwk59hUxc76AKWwp25CL76rE2vZc5f",
	},
	{
		name: "Other (Non-Verified)",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png?collectionLogo=true",
		verifiedCreatorAddress: "other",
	},
	{
		name: "Solana Monkey Business",
		imageUrl: "https://i.imgur.com/bMH6qNc.png?collectionLogo=true",
		verifiedCreatorAddress: "mdaoxg4DVGptU4WSpzGyVpK3zqsgn7Qzx5XNgWTcEA2",
	},
	{
		name: "SMB Gen3",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/smb_gen3_pfp_1688353503184.png?collectionLogo=true",
		verifiedCreatorAddress: "HV4Nvm9zHfNA43JYYkjZu8vwqiuE8bfEhwcKFfyQ65o5",
	},

	{
		name: "Okay Bears",
		imageUrl:
			"https://bafkreidgfsdjx4nt4vctch73hcchb3pkiwic2onfw5yr4756adchogk5de.ipfs.dweb.link/?collectionLogo=true",
		verifiedCreatorAddress: "3xVDoLaecZwXXtN59o6T3Gfxwjcgf8Hc9RfoqBn995P9",
	},

	{
		name: "Degenerate Ape Academy",
		imageUrl:
			"https://bafkreie44hlb5xve225n6p2siebaevxiwha37aefyvs2ul2kx2akalnree.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "DC2mkgwhy56w3viNtHDjJQmc7SGu2QX785bS4aexojwX",
	},

	{
		name: "Claynosaurz",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/claynosaurz_pfp_1679930706147.jpeg?collectionLogo=true",
		verifiedCreatorAddress: "AoebZtN5iKpVyUBc82aouWhugVknLzjUmEEUezxviYNo",
	},

	{
		name: "GGSG: Galactic Geckos",
		imageUrl: "https://i.imgur.com/PfaeoXm.png?collectionLogo=true",
		verifiedCreatorAddress: "FEg3mmpcrcRsVTuc2n3oghHpRvAtEJJau4KWjaPpLKcA",
	},

	{
		name: "Famous Fox Federation",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/famous_fox_federation_pfp_1679672949828.gif?collectionLogo=true",
		verifiedCreatorAddress: "D3XrkNZz6wx6cofot7Zohsf2KSsu2ArngNk8VqU9cTY3",
	},

	{
		name: "Degen Fat Cats",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/degenfatcats_pfp_1657860808010.png?collectionLogo=true",
		verifiedCreatorAddress: "6YRXxDFB92WE98c7YSM4itZw7ZbpeN5cwZFnhNPKKHPG",
	},

	{
		name: "ABC",
		imageUrl:
			"https://bafybeie4f7n526mxpp2jgixpc4e7b7vpkae4sglks37lzj7tiwghxyvel4.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "GVkb5GuwGKydA4xXLT9PNpx63h7bhFNrDLQSxi6j5NuF",
	},

	{
		name: "JellyRascals",
		imageUrl:
			"https://bafkreihdmfncyqklxvthlns42jr4nwvz2pog53ganhbodkki4dhn6vgjky.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "Cq3DFkKfsFYB3rV33d1y6ZDEkFPuhXqmbpjrL5DQAwtG",
	},

	{
		name: "Taiyo Robotics",
		imageUrl:
			"https://storage.googleapis.com/feliz-crypto/project_photos/taiyorobotics.png?collectionLogo=true",
		verifiedCreatorAddress: "AsbhrsY4posEBmZ8vyAooKtpvyJoWQWTqsSnnZpDmChn",
	},

	{
		name: "Aurory",
		imageUrl:
			"https://arweave.net/XnmNKTJOthZ1PcqSspYLPTjFHGJRCe-lGrapzNM0VVQ?collectionLogo=true",
		verifiedCreatorAddress: "9vwYtcJsH1MskNaixcjgNBnvBDkTBhyg25umod1rgMQL",
	},

	{
		name: "Smyths",
		imageUrl:
			"https://bafkreia2ily4463e6gz5rrv6utqycpm2o4pggbzhx4fazhjain5qgruecy.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "8m2b8ar9BNZErJQgSBwY3eCe73yR4k9qHUxxGffxyw2d",
	},

	{
		name: "y00ts",
		imageUrl:
			"https://bafkreidc5co72clgqor54gpugde6tr4otrubjfqanj4vx4ivjwxnhqgaai.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "A4FM6h8T5Fmh9z2g3fKUrKfZn6BNFEgByR8QGpdbQhk1",
	},

	{
		name: "Photo Finish PFP",
		imageUrl:
			"https://www.arweave.net/7dntcPrPZeee702M5FBVCAOdnKVS5pGGQJVUsHKGCWI?ext=png?collectionLogo=true",
		verifiedCreatorAddress: "BuQyrYf1fusgCh8mQRHH2dtEaU2wNhPuL6su7QTLWT5C",
	},

	{
		name: "Degenerate Drop Bears",
		imageUrl:
			"https://bafkreiaa3ncbdhrq2xx3j5gcxjxg6qn7zwsn2axw73pbfgfuq72s7imbpy.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "daaLrDfvcT4joui5axwR2gCkGAroruJFzyVsacU926g",
	},

	{
		name: "Catalina Whale Mixer",
		imageUrl:
			"https://bafkreicadecx3rbffm6tqtgtc67bi6r2xiibtmawcejje7pfu7id2zxwwy.ipfs.dweb.link/?collectionLogo=true",
		verifiedCreatorAddress: "DvZfhaWCjA2uCWgimRm6F59vHkeXyGvEvvVQPhiqosn2",
	},

	{
		name: "Degenerate Trash Pandas",
		imageUrl:
			"https://bafkreihiyquvbkmwbrqfl62gdrwuqesc5lkwfqpsvcaimsrtdgi3ujy5ry.ipfs.dweb.link/?collectionLogo=true",
		verifiedCreatorAddress: "CLErvyrMpi66RAxNV2wveSi25NxHb8G383MSVuGDgZzp",
	},

	{
		name: "Elixir: Ovols",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/elixir_ovols_pfp_1677693866649.png?collectionLogo=true",
		verifiedCreatorAddress: "ovo1kT7RqrAZwFtgSGEgNfa7nHjeZoK6ykg1GknJEXG",
	},

	{
		name: "sharx by sharky.fi",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/sharx_pfp_1664561059322.png?collectionLogo=true",
		verifiedCreatorAddress: "AqEQGEHqZb9zsDYtJtfjkTceVtwarLEopyoC5BzjYYaL",
	},

	{
		name: "The Heist",
		imageUrl:
			"https://bafybeigch4m7rbec2l255powwbjmacnyj5n5o54qcboiwfgs2nnw6thimq.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "7891HEu5N5UcoWFbmohK14fYbc1Dh5f3PApprjWK7y14",
	},

	{
		name: "Cyber Frogs",
		imageUrl:
			"https://www.arweave.net/OHFIbHqpFpgERaUhApaCFCwclAP_KrBoD0MixurXTDk?ext=png?collectionLogo=true",
		verifiedCreatorAddress: "frogQCpP8LpgfhhpusLvNoRw6sjmsX3Vij7MF8KtHn2",
	},

	{
		name: "Transdimensional Foxes",
		imageUrl:
			"https://bafkreiatsxp4ygj4muopasqisvjpvuhuvdk734zldbhvkx74hfnhik6mja.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "GFQBHxuNcAwYwHtEpggMwbH5kwV6Ne4JowHbLVfsMC6C",
	},

	{
		name: "Solcasino.io",
		imageUrl:
			"https://bafybeidd6mu775yyagyr4vgo6y2ayb5dc7vylv5arnd3mupbscek5zveya.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "2oGBtueeuAqrv6ry881eAHx8ApEJCtw3N7wFQPYDDqxP",
	},

	{
		name: "Stoned Ape Crew",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/stoned_ape_crew_pfp_1676720038688.png/?collectionLogo=true",
		verifiedCreatorAddress: "7RCBr3ZQ8yhY4jHpFFo3Kmh7MnaCPi1bFuUgXUB9WURf",
	},

	{
		name: "Gods",
		imageUrl:
			"https://bafybeigp5cflx5gs4mhjcwrlspsffkjqntpcprtny63tllfxg3ucoygnjq.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "ALNcW6QDNf7H4iNiTM3FD16LZ4zMGyEeCYQiE1AbCoXk",
	},

	{
		name: "Taiyo Pilots",
		imageUrl:
			"https://bafkreifx7fd32whlosropuzrx3253xflalbksp4geknglkrlyeqy7lf67m.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "rLNTGqK3VmEapEgcV7qL3oboBzvVTmNehYMXMLYtCRK",
	},

	{
		name: "Lily",
		imageUrl:
			"https://bafkreihwrpyr52wax3i5drzi5pg4v2wrgylpwi54im7qb7nzz7tpdsmmzm.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "B4x93Px5YYcQdpvEKmbPMWKGC5a8hytNqpitQFsEAjDx",
	},

	{
		name: "Liberty Square: The Sinister Squirrel Syndicate",
		imageUrl:
			"https://bafybeicg2dislxyztyix6efaatdke3ll76goy3qiqtdnj5bszxhmpu3rki.ipfs.nftstorage.link/PFP_New.png/?collectionLogo=true",
		verifiedCreatorAddress: "8jttAmtAaB7AuDiuEzxwRkqS73TCDYJ3CKZispNwvnAN",
	},

	{
		name: "Primates",
		imageUrl:
			"https://bafybeifnx4apyushfc3i2tg5wx2xgudcfxgisd2sven2mhxixpghuo2jeu.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "CvZY2tZhWEwYnuSoNAjGekmt9Sb5Crhq9DMYTtyup7s4",
	},

	{
		name: "Cets",
		imageUrl:
			"https://storage.googleapis.com/feliz-crypto/project_photos/cetsoncreck.png/?collectionLogo=true",
		verifiedCreatorAddress: "EcUAwbpczgPqqhG1zVuPnojkCSR4faiyNyAE98jp57P1",
	},
	{
		name: "Bored Ape Solana Club",
		imageUrl:
			"https://bafkreihmrwipluc6ro6uzl5ue2mkknexkkxw34df5icy4pzoh54lieexcq.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "3imuGagcaxe4hxLULy6Framn1pwSpaM7trF6tSo7aUGQ",
	},
	{
		name: "Matrica Labs: Pixels",
		imageUrl:
			"https://i.seadn.io/gae/w62pnUlmErfFAz_TlEjTpw0wkLgqfOw4ihEB_VZlAoCeHNPPNEduWxirrvLYvMyw4PH6sAXyo7GBqgDAstkcQVjmYiRBOaJeebkZ?auto=format&w=256/?collectionLogo=true",
		verifiedCreatorAddress: "AwYaJ66cz7BgRK11NwNW8XzHYk5k5TCoNMvnMU6KZSTs",
	},

	{
		name: "Droid Capital",
		imageUrl:
			"https://bafybeigg33rs7ummf5mkwttlyg6qtu63glbtbj3fija3zifvh5gsanmv2i.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "C88kKdEmTrCh43GC1w3BUFLsW3oKMEWWBGCyweQAaEYa",
	},
	{
		name: "Jikan Studios",
		imageUrl:
			"https://bafkreihav5rr3p3rphpex6gzieb6rf5qorgipg5z44w3qs7672cryq346u.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "9EjRje8w4bzk6fPeVUxLqTUXBfcyAtDRr36unAeSvFKg",
	},
	{
		name: "Alpha Pharaohs",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/alpha_pharaohs_pfp_1655055534354.gif/?collectionLogo=true",
		verifiedCreatorAddress: "DRqsx7jRNqFyvHeTirjwRGQDCsvB2MvbovAfc6fiDKuk",
	},

	{
		name: "Famous Fox Dens",
		imageUrl:
			"https://bafybeieoohxemxjxiztuftvrczcrazaa7nafrmcskbxu6tdzfqelpbceui.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "Ai8zx1jocBVtejc68swEYCK6r7DKpNwT8VcHec35jrWc",
	},

	{
		name: "Trippin Ape Tribe",
		imageUrl: "https://i.imgur.com/iFgvQva.png/?collectionLogo=true",
		verifiedCreatorAddress: "EZcZTsLpvdisPgQy5TcCALYgmGKRMNvvyJ89t1LFWmum",
	},
	{
		name: "Boryoku Dragonz",
		imageUrl: "https://boryokudragonz.io/dragonz-ani.gif/?collectionLogo=true",
		verifiedCreatorAddress: "DRGNjvBvnXNiQz9dTppGk1tAsVxtJsvhEmojEfBU3ezf",
	},

	{
		name: "Bubblegoose Ballers",
		imageUrl:
			"https://i.seadn.io/gcs/files/9d97a5169d9b588aec10f0f5ce4c7fe5.gif?auto=format&w=256&collectionLogo=true",
		verifiedCreatorAddress: "DTXRnNyYj72aZtFuVs71gLWus2C8SHnvPeFhWd6rNAhg",
	},
	{
		name: "Lotus Gang NFT",
		imageUrl:
			"https://i.seadn.io/gae/2huFYdsSnwaFv1QUIEuiYwITIjKYxq3DAtEN1PiMy_bM6nyNTz9c2cgXrjN2OVFBgBSi9PeHZb5a6q8ZR5nu2cdSh1F86J0zifqv4OQ?auto=format&collectionLogo=true",
		verifiedCreatorAddress: "3n1mz8MyqpQwgX9E8CNPPZtAdJa3aLpuCSMbPumM9wzZ",
	},

	{
		name: "GhostKidDAO",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/ghost_kid_dao_pfp_1662325189064.gif?collectionLogo=true",
		verifiedCreatorAddress: "HwmaNqSh7JAfwufTVjNdh7KjDnDsbHzYVWB99PVEUjts",
	},

	{
		name: "Turtles Classic",
		imageUrl: "https://i.imgur.com/DnOVkxY.png?collectionLogo=true",
		verifiedCreatorAddress: "4kUqMiYz28TgDf7j3zRdXJBXF9k6kJG7QzrnJMAvNiSR",
	},

	{
		name: "Bridged",
		imageUrl:
			"https://bafybeihb3bkn4gy3zqkbuycyvu4ma6yf4o4vrz5e5uliw74iclfwnmpw5a.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "7BVRY49u4GXXnUFbSHMNW218TVHYqZaENNWrzt3iaeeF",
	},
	{
		name: "Dazed Ducks",
		imageUrl:
			"https://bafkreign5cm5ngvlj2r325cxux3v73riroxjkr3bzmqpgxkzq63tkyvg4e.ipfs.nftstorage.link/?collectionLogo=true",
		verifiedCreatorAddress: "5jvdkYbLZjgMx822eEMeT2QFuQD6yN6VRLqEihSWfoG4",
	},

	{
		name: "Solsteads Surreal Estate",
		imageUrl:
			"https://www.arweave.net/WKfr0c79-4ExF_lEBkl4eQlqnPXrNWIj5W7CppmJZw4?ext=png?collectionLogo=true",
		verifiedCreatorAddress: "7CHTGqC92K2AGwMZ5n92hykdzUAWbVpCvQ4jrLA7dCu3",
	},
	{
		name: "SoDead",
		imageUrl:
			"https://creator-hub-prod.s3.us-east-2.amazonaws.com/sodead_pfp_1677109553945.gif?collectionLogo=true",
		verifiedCreatorAddress: "Bm1Dy1qjqBd9crwpunnve1RejrxVDtddvyCfqhAebDQ4",
	},

	{
		name: "Degens",
		imageUrl:
			"https://arweave.net/pDnqZ0Py8a41C3DYcBCIQ0CW5FnCxiWO1Pjp5m901-M?collectionLogo=true",
		verifiedCreatorAddress: "7pZ17ia3dMCWimMZ1eX5iGRuhV7W1P7pvngbM1fpP9WQ",
	},

	{
		name: "Solana Monke Rejects",
		imageUrl:
			"https://i.seadn.io/gae/ltEPCCcoqOZ10SKHu47rugw8VpYAbICAzYzF9bcWKHZPI9i3jF16v0D_-fT8ZTLz-FgIHtQJaoC2mK5w1Pe93zm3F3gTGMAIPOVqKQ?auto=format&collectionLogo=true",
		verifiedCreatorAddress: "Ed22DSqn7hdgbGQ7mPDkF3LSge1FX4UxZKDVDwkVDnWz",
	},

	{
		name: "Mad Lads",
		imageUrl:
			"https://i.seadn.io/gcs/files/85e6f92ba37352f09c3fe2f42e237201.webp?auto=format&dpr=1&w=500?collectionLogo=true",
		verifiedCreatorAddress: "5XvhfmRjwXkGp3jHGmaKpqeerNYjkuZZBYLVQYdeVcRv",
	},

	{
		name: "Oogy",
		imageUrl:
			"https://i.seadn.io/gcs/files/925dd5627d8c4a5e8c3a952dfb15e8e1.png?auto=format&dpr=1&w=500&collectionLogo=true",
		verifiedCreatorAddress: "27mpz3wfPMUtS4EtPWjEHxHJeqWE9NP7ENKErK573pqH",
	},

	{
		name: "Crypto Duck Punkz",
		imageUrl:
			"https://i.seadn.io/gae/jYw5JfTgpi3Pu2pEHZw-66Wq37-sJSIGUAkYgQ2VAB0Zh9uhWZ8H2v0rotTf0oVY8TSeY81FLQtwi8oK2Xwm7b2hVZf3WkdHf5e4?auto=format&dpr=1&w=500&collectionLogo=true",
		verifiedCreatorAddress: "DjyHEfUv6EhVwchqjmy3b46Td2ZzXenHzUKdu6yJfCeZ",
	},

	{
		name: "Doge Capital",
		imageUrl:
			"https://storage.googleapis.com/feliz-crypto/project_photos/dogecapital.png?collectionLogo=true",
		verifiedCreatorAddress: "HbGAnEWsgGsh3gJCeLJTUEy2kjixucaMYKD9eLMGwg9U",
	},
	{
		name: "Nekozuma",
		imageUrl:
			"https://i.seadn.io/gae/-VCTaT1vwxKdcHhf2geyJLzs78ybSNpePe5wQA7ECF6keB57fEKwuct5230hr3PEJL1DG36W2QIGkssqDKZUYev9Lnz_SwRpowSS2mk?auto=format&dpr=1&w=256&collectionLogo=true",
		verifiedCreatorAddress: "6HSgodmEi3QyYdZd1gwq8X6MQ2RcWqgPKa2Wzag83wKE",
	},

	{
		name: "Defi Degenerates",
		imageUrl:
			"https://bafybeic3vwsex2zuph4hidrzpr72l5ir4rcciscxnsuxo4rz3mbzwthdfi.ipfs.nftstorage.link/7.jpg?collectionLogo=true",
		verifiedCreatorAddress: "BjeuFF8MsbTx7SmS1XvF2KjTiKPJf689AsBZsa4rVjCR",
	},
];
