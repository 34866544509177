import React from "react";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";

import { TOOLS_DROPDOWN, MONKEY_BABY_DROPDOWN } from "../../values/dropdowns";

import DropdownMobileMenu from "./DropdownMobileMenu";

export const MobileFullscreenMenu = ({ setViewMobileMenu }) => {
	return (
		<div className="fixed w-screen h-screen overflow-y-auto pb-24 z-[100] lg:hidden bg-white">
			<div className="flex flex-col justify-center items-center pt-6 pb-20 w-full">
				<div className="flex flex-row justify-start w-full px-6 mb-8">
					<button
						onClick={() => {
							setViewMobileMenu(false);
						}}
						className="flex lg:hidden mr-4 text-dark-blue "
					>
						<IoClose size={32} />
					</button>
				</div>

				<NavLink
					to={"/"}
					onClick={() => {
						setViewMobileMenu(false);
					}}
					className={({ isActive }) =>
						`flex flex-row justify-center items-center px-4 py-3 text-lg text-center  hover:text-pink  ${
							isActive ? `text-pink` : `text-dark-blue `
						}`
					}
				>
					<p className="font-cubano ">Collage Generator</p>
				</NavLink>

				<DropdownMobileMenu
					title={"Monkey Babies"}
					dropdownItems={MONKEY_BABY_DROPDOWN}
					externalLinks={true}
					{...{ setViewMobileMenu }}
				/>

				<DropdownMobileMenu
					title={"Tools"}
					dropdownItems={TOOLS_DROPDOWN}
					externalLinks={true}
					{...{ setViewMobileMenu }}
				/>
			</div>
		</div>
	);
};

export default MobileFullscreenMenu;
