import React, { useEffect } from "react";
import useWindowDimensions from "../../../services/utils/Dimensions";

/** Third Party Libraries */
import ColorPicker from "../../helpers/ColorPicker";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

/** Icons */
import {
	BsFillInfoCircleFill,
	BsTextCenter,
	BsTextLeft,
	BsTextRight,
} from "react-icons/bs";
import { FaPaintBrush } from "react-icons/fa";
//import { HiPaintBrush } from "react-icons/hi2";
import {
	MdOutlineVerticalAlignCenter,
	MdOutlineVerticalAlignBottom,
	MdOutlineVerticalAlignTop,
} from "react-icons/md";

/** Components */
import { TooltipView } from "../../helpers/TooltipView";
import SortCollections from "../SortCollections";
import cloneDeep from "lodash.clonedeep";

export const ResultsHeader = ({
	displayCollage,
	displayBorder,
	setDisplayBorder,
	displayPrices,
	setDisplayPrices,
	displayBkgColorChange,
	setDisplayBkgColorChange,
	displayBorderColorChange,
	setDisplayBorderColorChange,
	borderWidth,
	setBorderWidth,
	textSize,
	setTextSize,
	borderCustomColor,
	setBorderCustomColor,
	bkgCustomColor,
	setBkgCustomColor,
	grayscaleOn,
	setGrayscaleOn,
	collageText,
	setCollageText,
	verticalAlignText,
	setVerticalAlignText,
	horizontalAlignText,
	setHorizontalAlignText,
	selectedNfts,
	numOfNftsPerCol,
	numOfNftsPerRow,
	defaultPriceText,
	setDefaultPriceText,
	lastActiveId,
	setLastActiveId,
	priceMap,
	setPriceMap,

	viewCollectionSorting,
	setViewCollectionSorting,
	selectedCollectionImages,
	setSelectedCollectionImages,

	otcPosition,
	setOtcPosition,
	otcSize,
	setOtcSize,
}) => {
	const windowDimensions = useWindowDimensions();

	// When the user types in text, change collage text state
	function handleTextChange(e) {
		setCollageText(e.target.value);
	}

	function handleDefaultPriceTextChange(e) {
		setDefaultPriceText(e.target.value);
		const map = cloneDeep(priceMap);
		selectedNfts.forEach((nft) => {
			if (
				map?.get(nft?.image) === defaultPriceText ||
				map?.get(nft?.image) === null ||
				map?.get(nft?.image) === ""
			) {
				map.set(nft?.image, e.target.value);
			}
		});
		setPriceMap(map);
	}

	function clearPrices() {
		const map = cloneDeep(priceMap);
		priceMap.forEach((price, key) => {
			map.set(key, "");
		});
		setPriceMap(map);
		setDefaultPriceText("");
	}

	function handleActivePriceTextChange(e) {
		const map = cloneDeep(priceMap);
		map.set(lastActiveId, e.target.value);
		setPriceMap(map);
	}

	useEffect(() => {
		if (priceMap === null) {
			const map = new Map();
			selectedNfts.forEach((nft) => {
				map.set(nft?.image, "");
			});
			setPriceMap(map);
		} else {
			const map = priceMap;
			selectedNfts.forEach((nft) => {
				if (
					priceMap?.get(nft?.image) == null ||
					priceMap?.get(nft?.image) == ""
				) {
					map.set(nft?.image, "");
				}
			});
			setPriceMap(map);
		}
		let activeIdExpired = true;
		const selectedImgList = selectedNfts.map((elem) => elem.image);
		for (let i = 0; i < selectedImgList.length; i++) {
			if (selectedImgList[i] === lastActiveId) {
				activeIdExpired = false;
			}
		}
		if (activeIdExpired) setLastActiveId(null); // Resets active NFT id for price change
	}, [selectedNfts]);

	return (
		<>
			<h1 className="text-2xl font-cubano pt-2 pb-4 text-dark-blue">
				Solana NFT Collage Maker
			</h1>
			<div className="flex flex-row justify-center items-center w-full px-4 mb-2 flex-wrap">
				<button
					disabled={!displayCollage}
					style={!displayCollage ? { opacity: 0.45 } : {}}
					onClick={() => {
						setDisplayBorder(!displayBorder);
					}}
					className={
						"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
					}
				>
					{displayBorder ? "Border: On" : "Border: Off"}
				</button>
				<button
					disabled={!displayCollage}
					style={!displayCollage ? { opacity: 0.45 } : {}}
					onClick={() => {
						if (
							displayBkgColorChange === true &&
							displayBorderColorChange === false
						)
							setDisplayBkgColorChange(false);
						setDisplayBorderColorChange(!displayBorderColorChange);
					}}
					className={`rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm
									${
										displayBorderColorChange && displayCollage
											? `bg-pink border-white text-white`
											: `bg-white border-dark-grey text-dark-gray`
									}`}
				>
					Edit Border Color
				</button>

				<div className="max-w-[175px] mr-4 mb-2 flex flex-col text-center">
					<p className="font-cubano text-sm text-dark-gray min-w-[175px]">
						Border Width: {borderWidth}
					</p>
					<>
						{" "}
						<Slider
							disabled={!displayCollage}
							min={0.5}
							max={10}
							onChange={(val) => {
								setBorderWidth(val);
							}}
							width={10}
							value={borderWidth}
							step={0.5}
							trackStyle={{
								backgroundColor: "#e41e8e",
								opacity: !displayCollage ? 0.33 : 1,
							}}
							handleStyle={{
								borderColor: !displayCollage ? "gray" : "#e41e8e",
								opacity: !displayCollage ? 0.33 : 1,
							}}
						/>
					</>
				</div>

				<button
					disabled={!displayCollage}
					style={!displayCollage ? { opacity: 0.45 } : {}}
					onClick={() => {
						if (
							displayBorderColorChange === true &&
							displayBkgColorChange === false
						)
							setDisplayBorderColorChange(false);
						setDisplayBkgColorChange(!displayBkgColorChange);
					}}
					className={`rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm
									${
										displayBkgColorChange && displayCollage
											? `bg-pink border-white text-white`
											: `bg-white border-dark-grey text-dark-gray`
									}`}
				>
					Edit Bg Color
				</button>

				<div className="mb-2 mr-4 flex flex-row items-center">
					<button
						disabled={!displayCollage}
						style={!displayCollage ? { opacity: 0.45 } : {}}
						onClick={() => {
							setGrayscaleOn(!grayscaleOn);
						}}
						className={`rounded-xl py-2 px-4 mr-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm`}
					>
						Grayscale: {grayscaleOn ? "On" : "Off"}
					</button>
					<a
						data-tooltip-id="grayscale-tooltip"
						data-tooltip-content={
							"Grayscale may run into issues or take longer to load with GIF images and large collages."
						}
					>
						<BsFillInfoCircleFill className="text-dark-gray" size={12} />
					</a>
				</div>

				<input
					className="flex max-w-[300px] rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30
					 focus:outline-pink	bg-white text-dark-gray font-cubano text-sm"
					placeholder="Enter Text Here"
					disabled={!displayCollage}
					style={!displayCollage ? { opacity: 0.45 } : {}}
					value={collageText}
					onChange={(e) => {
						handleTextChange(e);
					}}
				/>

				<div
					style={!displayCollage ? { opacity: 0.45 } : {}}
					className="flex flex-row rounded-xl py-2 px-2 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white"
				>
					<button
						onClick={() => {
							setVerticalAlignText("bottom");
						}}
						disabled={!displayCollage}
					>
						<MdOutlineVerticalAlignBottom
							className={`${
								verticalAlignText === "bottom" ? "text-pink" : "text-dark-gray"
							} mr-2`}
							size={18}
						/>
					</button>
					<button
						onClick={() => {
							setVerticalAlignText("center");
						}}
						disabled={!displayCollage}
					>
						<MdOutlineVerticalAlignCenter
							className={`${
								verticalAlignText === "center" ? "text-pink" : "text-dark-gray"
							} mr-2`}
							size={18}
						/>
					</button>
					<button
						onClick={() => {
							setVerticalAlignText("top");
						}}
						disabled={!displayCollage}
					>
						<MdOutlineVerticalAlignTop
							className={`${
								verticalAlignText === "top" ? "text-pink" : "text-dark-gray"
							}`}
							size={18}
						/>
					</button>
				</div>

				<div
					style={!displayCollage ? { opacity: 0.45 } : {}}
					className="flex flex-row rounded-xl py-2 px-2 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white"
				>
					<button
						onClick={() => {
							setHorizontalAlignText("left");
						}}
						disabled={!displayCollage}
					>
						<BsTextLeft
							className={`${
								horizontalAlignText === "left" ? "text-pink" : "text-dark-gray"
							} mr-2`}
							size={18}
						/>
					</button>
					<button
						onClick={() => {
							setHorizontalAlignText("center");
						}}
						disabled={!displayCollage}
					>
						<BsTextCenter
							className={`${
								horizontalAlignText === "center"
									? "text-pink"
									: "text-dark-gray"
							} mr-2`}
							size={18}
						/>
					</button>
					<button
						onClick={() => {
							setHorizontalAlignText("right");
						}}
						disabled={!displayCollage}
					>
						<BsTextRight
							className={`${
								horizontalAlignText === "right" ? "text-pink" : "text-dark-gray"
							}`}
							size={18}
						/>
					</button>
				</div>

				<div className="max-w-[175px] mr-4 mb-2 flex flex-col text-center">
					<p className="font-cubano text-sm text-dark-gray min-w-[175px]">
						Text Size: {textSize}
					</p>
					<>
						{" "}
						<Slider
							disabled={!displayCollage}
							min={6}
							max={36}
							onChange={(val) => {
								setTextSize(val);
							}}
							width={10}
							value={textSize}
							step={2}
							trackStyle={{
								backgroundColor: "#e41e8e",
								opacity: !displayCollage ? 0.33 : 1,
							}}
							handleStyle={{
								borderColor: !displayCollage ? "gray" : "#e41e8e",
								opacity: !displayCollage ? 0.33 : 1,
							}}
						/>
					</>
				</div>

				<button
					onClick={() => {
						setViewCollectionSorting(!viewCollectionSorting);
					}}
					disabled={
						!displayCollage ||
						!selectedCollectionImages ||
						selectedCollectionImages.length <= 1
					}
					className={
						"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm disabled:opacity-40"
					}
				>
					Sort by Collection :{" "}
					{displayCollage && viewCollectionSorting ? "On" : "Off"}
				</button>

				<button
					disabled={!displayCollage}
					style={!displayCollage ? { opacity: 0.45 } : {}}
					onClick={() => {
						setDisplayPrices(!displayPrices);
					}}
					className={
						"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
					}
				>
					{displayPrices ? "Add Prices: On" : "Add Prices: Off"}
				</button>

				{displayPrices && (
					<>
						<button
							disabled={!displayCollage}
							style={!displayCollage ? { opacity: 0.45 } : {}}
							onClick={() => {
								clearPrices();
							}}
							className={
								"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
							}
						>
							Clear Prices
						</button>

						<input
							className="flex max-w-[300px] rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30
					 focus:outline-pink	bg-white text-dark-gray font-cubano text-sm"
							placeholder="Enter Default Price"
							disabled={!displayPrices || !displayCollage}
							style={!displayCollage || !displayPrices ? { opacity: 0.45 } : {}}
							value={defaultPriceText}
							onChange={(e) => {
								handleDefaultPriceTextChange(e);
							}}
						/>

						<button
							disabled={!displayCollage}
							style={!displayCollage ? { opacity: 0.45 } : {}}
							onClick={() => {
								if (otcPosition == "bottom") {
									setOtcPosition("top");
									return;
								} else {
									setOtcPosition("bottom");
								}
							}}
							className={
								"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
							}
						>
							OTC Position:{" "}
							{otcPosition[0].toUpperCase() + otcPosition.substring(1)}
						</button>

						<div className="max-w-[175px] mr-4 mb-2 flex flex-col text-center">
							<p className="font-cubano text-sm text-dark-gray min-w-[175px]">
								OTC Size: {otcSize}%
							</p>
							<>
								{" "}
								<Slider
									disabled={!displayCollage}
									min={0}
									max={100}
									onChange={(val) => {
										setOtcSize(val);
									}}
									width={10}
									value={otcSize}
									step={5}
									trackStyle={{
										backgroundColor: "#e41e8e",
										opacity: !displayCollage ? 0.33 : 1,
									}}
									handleStyle={{
										borderColor: !displayCollage ? "gray" : "#e41e8e",
										opacity: !displayCollage ? 0.33 : 1,
									}}
								/>
							</>
						</div>
					</>
				)}

				<div className="flex flex-row items-center justify-center my-2 mr-2">
					<p className="text-md mr-2 font-roboto font-semibold text-dark-blue">
						Why are there watermarks on the images?
					</p>
					<a
						data-tooltip-id="watermark-tooltip"
						data-tooltip-content={
							"The watermarks are temporary and will disappear after you purchase your collage. FREE for MBB Holders."
						}
					>
						<BsFillInfoCircleFill className="text-dark-gray" size={12} />
					</a>
				</div>

				{displayCollage &&
					viewCollectionSorting &&
					selectedCollectionImages &&
					selectedCollectionImages.length > 1 && (
						<div className="flex flex-row items-center justify-center my-4 flex-wrap w-full max-w-3xl">
							<p className="text-md mr-4 font-roboto font-semibold text-dark-blue">
								Sort NFTs by Collection
							</p>

							<SortCollections
								{...{ selectedCollectionImages, setSelectedCollectionImages }}
							/>
						</div>
					)}

				{windowDimensions.width >= 1024 && displayCollage && (
					<div className="flex flex-row items-center justify-center my-1">
						<p className="text-md font-roboto font-medium text-dark-blue">
							Drag and Drop the images below to rearrange the collage
						</p>
					</div>
				)}

				{numOfNftsPerRow !== -1 &&
					selectedNfts.length > numOfNftsPerCol * numOfNftsPerRow && (
						<div className="flex flex-row items-center justify-center my-1">
							<p className="text-md font-roboto font-medium text-pink">
								Note: For optimal viewing on this grid, a maximum of{" "}
								{(numOfNftsPerCol * numOfNftsPerRow)?.toFixed(0) + " "}
								NFTs are able to be selected.
							</p>
						</div>
					)}
			</div>

			{displayCollage && displayBorderColorChange && (
				<ColorPicker
					customColor={borderCustomColor}
					setCustomColor={setBorderCustomColor}
				/>
			)}
			{displayCollage && displayBkgColorChange && (
				<ColorPicker
					customColor={bkgCustomColor}
					setCustomColor={setBkgCustomColor}
				/>
			)}

			{displayPrices && (
				<div className="flex flex-row items-center mb-4">
					{lastActiveId != null ? (
						<>
							<img
								//crossOrigin="Anonymous"
								style={{ display: "block", width: "70px", height: "70px" }}
								src={lastActiveId}
								alt={"Active NFT Image"}
								key={"activeId"}
								className="border-4 border-pink"
							/>
							<div className="flex flex-col pl-2">
								<p className="text-md font-roboto font-semibold mb-1 text-dark-blue">
									Edit Custom Price for Active NFT
								</p>
								<input
									className="flex max-w-[300px] rounded-xl ml-2 py-2 px-4 border-2 border-dark-grey border-opacity-30
					 focus:outline-pink	bg-white text-dark-gray font-cubano text-sm"
									placeholder="Enter Active NFT Price"
									disabled={!displayPrices || !displayCollage}
									style={
										!displayCollage || !displayPrices ? { opacity: 0.45 } : {}
									}
									value={
										priceMap?.get(lastActiveId)
											? priceMap?.get(lastActiveId)
											: ""
									}
									onChange={(e) => {
										handleActivePriceTextChange(e);
									}}
								/>
							</div>
						</>
					) : (
						<p className="text-md font-roboto font-semibold mb-1 text-pink">
							Click an NFT to Custom Set a Price
						</p>
					)}
				</div>
			)}

			{windowDimensions.width >= 1024 && !displayCollage && (
				<div className="max-w-[500px] min-h-[200px] flex flex-col justify-center items-center mt-2 border border-light-grey">
					<FaPaintBrush size={36} color="#818181" />
					<h3 className="text-xl font-cubano py-4  text-dark-gray">
						Complete instructions on the left side of the screen to proceed.
					</h3>
				</div>
			)}

			<TooltipView id="watermark-tooltip" />
			<TooltipView
				id="grayscale-tooltip"
				className="bg-black text-white font-roboto font-medium max-w-[95%]"
			/>
		</>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const displayCollage =
		prevProps?.displayCollage === nextProps?.displayCollage;
	const displayBorder = prevProps?.displayBorder === nextProps?.displayBorder;
	const displayPrices = prevProps?.displayPrices === nextProps?.displayPrices;
	const displayBkgColorChange =
		prevProps?.displayBkgColorChange === nextProps?.displayBkgColorChange;
	const displayBorderColorChange =
		prevProps?.displayBorderColorChange === nextProps?.displayBorderColorChange;
	const borderWidth = prevProps?.borderWidth === nextProps?.borderWidth;
	const borderCustomColor =
		prevProps?.borderCustomColor === nextProps?.borderCustomColor;
	const bkgCustomColor =
		prevProps?.bkgCustomColor === nextProps?.bkgCustomColor;
	const grayscaleOn = prevProps?.grayscaleOn === nextProps?.grayscaleOn;
	const collageText = prevProps?.collageText === nextProps?.collageText;
	const verticalAlignText =
		prevProps?.verticalAlignText === nextProps?.verticalAlignText;
	const horizontalAlignText =
		prevProps?.horizontalAlignText === nextProps?.horizontalAlignText;

	const numOfNftsPerCol =
		prevProps?.numOfNftsPerCol === nextProps?.numOfNftsPerCol;
	const numOfNftsPerRow =
		prevProps?.numOfNftsPerRow === nextProps?.numOfNftsPerRow;
	const selectedNfts = prevProps?.selectedNfts === nextProps?.selectedNfts;
	const lastActiveId = prevProps?.lastActiveId === nextProps?.lastActiveId;
	const priceMap = prevProps?.priceMap === nextProps?.priceMap;
	const otcPosition = prevProps?.otcPosition === nextProps?.otcPosition;
	const otcSize = prevProps?.otcSize === nextProps?.otcSize;

	const viewCollectionSorting =
		prevProps?.viewCollectionSorting == nextProps?.viewCollectionSorting;
	const selectedCollectionImages =
		prevProps?.selectedCollectionImages == nextProps?.selectedCollectionImages;

	return (
		displayCollage &&
		displayBorder &&
		displayPrices &&
		displayBkgColorChange &&
		displayBorderColorChange &&
		borderWidth &&
		borderCustomColor &&
		bkgCustomColor &&
		grayscaleOn &&
		collageText &&
		verticalAlignText &&
		horizontalAlignText &&
		numOfNftsPerCol &&
		numOfNftsPerRow &&
		selectedNfts &&
		lastActiveId &&
		priceMap &&
		viewCollectionSorting &&
		selectedCollectionImages &&
		otcPosition &&
		otcSize
	);
};
export default React.memo(ResultsHeader, arePropsEqual);
