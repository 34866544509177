/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/auth";
import "firebase/compat/firestore";

/**
 * Creates collection snapshot instance. Used to access firestore collection(s) data.
 * @param collectionName String. Collection name
 */
export const getCollectionReference = (collectionName) => {
	return firebase.firestore().collection(collectionName);
};

// Access firebase functions
export const firebaseFunction = async (path, data) => {
	return firebase.functions().httpsCallable(path, { timeout: 540000 })(data);
};
