// Used to validate the wallet input to make sure there are no duplicates
export const walletDuplicates = (walletList) => {
	return new Promise((resolve, reject) => {
		try {
			const walletValsSoFar = [];
			for (let i = 0; i < walletList.length; ++i) {
				const currentWallet = walletList[i].replace(/\s/g, "");
				if (walletValsSoFar.includes(currentWallet)) {
					resolve(true);
					return;
				}
				walletValsSoFar.push(currentWallet);
			}
			resolve(false);
		} catch (error) {
			reject("error - ", error);
		}
	});
};

// Used to validate wallet input, ensure the user inputted a SOL address
export const validateWalletInput = (wallet) => {
	return new Promise((resolve, reject) => {
		try {
			if (wallet != null && wallet != "" && wallet.length > 35) {
				resolve(true);
			} else {
				resolve(false);
			}
		} catch (error) {
			reject("error - ", error);
		}
	});
};
