import React, { forwardRef, useState } from "react";
// import { watermarkIssueFlag } from "../../services/utils/WatermarkCheck";
import { IMAGEKIT_ENDPOINT } from "../../values/constants";

export const Item = forwardRef(
	(
		{
			id,
			withOpacity,
			isDragging,
			style,
			border,
			borderWidth,
			borderCustomColor,
			numOfNftsPerCol,
			maxCollageWidth,
			displayWatermark,
			mbbHolder,
			grayscaleOn,
			displayPrices,
			defaultPriceText,
			priceMap,
			otcPosition,
			otcSize,
			...props
		},
		ref
	) => {
		const inlineStyles = {
			opacity: withOpacity ? "0.5" : "1",
			transformOrigin: "50% 50%",
			height: maxCollageWidth / numOfNftsPerCol,
			width: maxCollageWidth / numOfNftsPerCol,
			cursor: isDragging ? "grabbing" : "grab",
			backgroundColor: "#ffffff",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			boxShadow: isDragging
				? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
				: "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
			transform: isDragging ? "scale(1.05)" : "scale(1)",
			overflow: "hidden",
			borderColor: borderCustomColor?.hex ? borderCustomColor?.hex : "#04004f",
			borderWidth: border && borderWidth ? borderWidth.toString() + "px" : null,
			...style,
		};

		const shrinkage = 150 - otcSize * 1.5;

		const priceStyles = {
			height: (maxCollageWidth - shrinkage) / numOfNftsPerCol / 4, // height: 1/4 the size of the collage img
			width: (maxCollageWidth - shrinkage) / numOfNftsPerCol / 1.1, // width: a little bit less than the size of the collage image
			fontSize: (maxCollageWidth - shrinkage) / numOfNftsPerCol / 6,
			// top: otcPosition === "top" ? 4 : null,
			// bottom: otcPosition === "bottom" ? 4 : null,
			marginTop:
				otcPosition === "bottom" ? maxCollageWidth / numOfNftsPerCol / 1.5 : 0,
			marginBottom:
				otcPosition === "top" ? maxCollageWidth / numOfNftsPerCol / 1.5 : 0,

			...style,
		};

		if (!id) return null;

		return (
			<div
				ref={ref}
				style={inlineStyles}
				{...props}
				className={`block ${border ? `border border-dark-blue` : ``}`}
			>
				<img
					crossOrigin="Anonymous"
					className="collageImage"
					style={{ display: "block" }}
					src={
						displayWatermark && !mbbHolder && !id?.includes("gif")
							? IMAGEKIT_ENDPOINT.BASIC_URL +
							  encodeURI(id) +
							  (encodeURI(id)?.includes("?") ? "&" : "?") +
							  (grayscaleOn
									? IMAGEKIT_ENDPOINT.WATERMARK_ON_GREYSCALE
									: IMAGEKIT_ENDPOINT.WATERMARK_ON)
							: IMAGEKIT_ENDPOINT.BASIC_URL +
							  (grayscaleOn
									? IMAGEKIT_ENDPOINT.NO_WATERMARK_GREYSCALE
									: IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR) +
							  encodeURI(id)
					}
					alt={"N/A NFT image"}
					key={"img" + id}
					onLoad={(event) => {
						const imgSrc = event.target.src;
						if (
							!imgSrc.includes("i-mbb-watermark") &&
							((displayWatermark && !mbbHolder && !imgSrc?.includes("gif")) ||
								!imgSrc.includes(
									IMAGEKIT_ENDPOINT.BASIC_URL +
										IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR
								))
						) {
							event.target.parentNode.removeChild(event.target);
						}
					}}
				/>

				{id?.includes("gif") && displayWatermark && !mbbHolder && (
					<img
						crossOrigin="Anonymous"
						src={IMAGEKIT_ENDPOINT.EXTERNAL_FULL_WATERMARK_URL}
						className={`absolute text-center z-100 w-[40px] h-auto opacity-80`}
					/>
				)}

				{displayPrices === true && !id?.includes("collectionLogo=true") && (
					<div className="absolute z-100 bg-black/60" style={priceStyles}>
						<p className="font-roboto font-medium text-white">
							{priceMap?.get(id) ? priceMap?.get(id) : "-"}
							{priceMap?.get(id) && priceMap?.get(id)?.length < 8 && " SOL"}
						</p>
					</div>
				)}
			</div>
		);
	}
);

export default Item;
