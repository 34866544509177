import React, { useState, useCallback } from "react";
import useWindowDimensions from "../../../services/utils/Dimensions";

/** Third Party Library - Drag and Drop */
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	DragOverlay,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
} from "@dnd-kit/sortable";

/** Components */
import { Grid } from "../../drag-and-drop/Grid";
import { SortableItem } from "../../drag-and-drop/SortableItem";
import { Item } from "../../drag-and-drop/Item";

export const DesktopCollage = ({
	selectedNfts,
	setSelectedNfts,
	bkgCustomColor,
	numOfNftsPerCol,
	numOfNftsPerRow,
	displayBorder,
	displayPrices,
	displayWatermark,
	mbbHolder,
	borderWidth,
	borderCustomColor,
	grayscaleOn,
	collageText,
	verticalAlignText,
	horizontalAlignText,
	defaultPriceText,
	setLastActiveId,
	priceMap,
	textSize,
	otcPosition,
	otcSize,
}) => {
	const windowDimensions = useWindowDimensions();

	/** ---- Drag and drop functionality on collage ---- */
	const [activeId, setActiveId] = useState(null);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	const handleDragStart = useCallback((event) => {
		setActiveId(event.active.id);
		if (!event.active.id?.includes("collectionLogo=true")) {
			setLastActiveId(event.active.id);
		}
	}, []);
	// Used to move an NFT on the collage
	const handleDragEnd = useCallback((event) => {
		const { active, over } = event;
		// If the NFT is placed on a new spot, update the location in selectedNfts
		if (active.id !== over.id) {
			// NOTE: Changed
			// setSelectedNfts((selectedNfts) => {
			// 	const oldIndex = selectedNfts.indexOf(active.id);
			// 	const newIndex = selectedNfts.indexOf(over.id);
			// 	return arrayMove(selectedNfts, oldIndex, newIndex);
			// });
			setSelectedNfts((selectedNfts) => {
				let oldIndex = -1;
				let newIndex = -1;
				for (let index = 0; index < selectedNfts.length; index++) {
					if (active.id.includes(selectedNfts[index].image)) {
						oldIndex = index;
					}
					if (over.id.includes(selectedNfts[index].image)) {
						newIndex = index;
					}
				}
				return arrayMove(selectedNfts, oldIndex, newIndex);
			});
		}
		setActiveId(null);
	}, []);
	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);

	return (
		<div
			id="collage"
			className="relative"
			style={{
				marginTop: 2,
				backgroundColor: bkgCustomColor?.hex ? bkgCustomColor?.hex : "#000",
				maxWidth: Math.min(
					100 * numOfNftsPerCol,
					windowDimensions.width >= 1024
						? windowDimensions.width / 2 - 40
						: windowDimensions.width - 10
				),
				minHeight:
					numOfNftsPerRow !== -1
						? (Math.min(
								100 * numOfNftsPerCol,
								windowDimensions.width >= 1024
									? windowDimensions.width / 2 - 40
									: windowDimensions.width - 10
						  ) /
								numOfNftsPerCol) *
						  numOfNftsPerRow
						: null,
			}}
		>
			{collageText !== "" && (
				<div
					className={`absolute z-[8] ${
						verticalAlignText === "bottom"
							? "bottom-0"
							: verticalAlignText === "top"
							? "top-0"
							: "top-1/2 -translate-y-1/2"
					} 
					${
						horizontalAlignText === "right"
							? "right-0"
							: horizontalAlignText === "left"
							? "left-0"
							: "left-1/2 -translate-x-1/2"
					}
					 bg-black bg-opacity-50 text-white max-w-full flex-wrap`}
				>
					{/* Text in the bottom right corner */}
					<div className="flex flex-col flex-wrap items-center justify-center mx-2 my-2">
						<p
							className="whitespace-normal"
							style={{ fontSize: textSize + "px" }}
						>
							{collageText}
						</p>
					</div>
				</div>
			)}
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
			>
				<SortableContext items={selectedNfts} strategy={rectSortingStrategy}>
					<Grid columns={numOfNftsPerCol}>
						{selectedNfts.map((nft, index) => (
							<>
								{(numOfNftsPerRow === -1 ||
									index < numOfNftsPerRow * numOfNftsPerCol) && (
									<SortableItem
										key={"sortableitem" + nft.image}
										id={nft.image}
										border={displayBorder}
										borderCustomColor={borderCustomColor}
										displayPrices={displayPrices}
										maxCollageWidth={Math.min(
											100 * numOfNftsPerCol,
											windowDimensions.width >= 1024
												? windowDimensions.width / 2 - 40
												: windowDimensions.width - 10
										)}
										{...{
											numOfNftsPerCol,
											borderWidth,
											displayWatermark,
											mbbHolder,
											grayscaleOn,
											defaultPriceText,
											priceMap,
											otcPosition,
											otcSize,
										}}
									/>
								)}
							</>
						))}
					</Grid>
				</SortableContext>
				<DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
					{activeId ? (
						<Item
							id={activeId}
							border={displayBorder}
							displayPrices={displayPrices}
							isDragging
							maxCollageWidth={Math.min(
								100 * numOfNftsPerCol,
								windowDimensions.width >= 1024
									? windowDimensions.width / 2 - 40
									: windowDimensions.width - 10
							)}
							{...{
								numOfNftsPerCol,
								borderWidth,
								displayWatermark,
								mbbHolder,
								grayscaleOn,
								defaultPriceText,
								priceMap,
								otcPosition,
								otcSize,
							}}
						/>
					) : null}
				</DragOverlay>
			</DndContext>
		</div>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const selectedNfts = prevProps?.selectedNfts === nextProps?.selectedNfts;
	const bkgCustomColor =
		prevProps?.bkgCustomColor === nextProps?.bkgCustomColor;
	const numOfNftsPerCol =
		prevProps?.numOfNftsPerCol === nextProps?.numOfNftsPerCol;
	const numOfNftsPerRow =
		prevProps?.numOfNftsPerRow === nextProps?.numOfNftsPerRow;
	const displayBorder = prevProps?.displayBorder === nextProps?.displayBorder;
	const displayPrices = prevProps?.displayPrices === nextProps?.displayPrices;
	const displayWatermark =
		prevProps?.displayWatermark === nextProps?.displayWatermark;
	const mbbHolder = prevProps?.mbbHolder === nextProps?.mbbHolder;
	const borderWidth = prevProps?.borderWidth === nextProps?.borderWidth;
	const borderCustomColor =
		prevProps?.borderCustomColor === nextProps?.borderCustomColor;
	const grayscaleOn = prevProps?.grayscaleOn === nextProps?.grayscaleOn;
	const collageText = prevProps?.collageText === nextProps?.collageText;
	const defaultPriceText =
		prevProps?.defaultPriceText === nextProps?.defaultPriceText;
	const verticalAlignText =
		prevProps?.verticalAlignText === nextProps?.verticalAlignText;
	const horizontalAlignText =
		prevProps?.horizontalAlignText === nextProps?.horizontalAlignText;
	const priceMap = prevProps?.priceMap === nextProps?.priceMap;
	const textSize = prevProps?.textSize === nextProps?.textSize;
	const otcPosition = prevProps?.otcPosition === nextProps?.otcPosition;
	const otcSize = prevProps?.otcSize === nextProps?.otcSize;

	return (
		selectedNfts &&
		numOfNftsPerCol &&
		numOfNftsPerRow &&
		bkgCustomColor &&
		displayBorder &&
		displayPrices &&
		displayWatermark &&
		mbbHolder &&
		borderWidth &&
		borderCustomColor &&
		grayscaleOn &&
		collageText &&
		defaultPriceText &&
		verticalAlignText &&
		horizontalAlignText &&
		priceMap &&
		textSize &&
		otcPosition &&
		otcSize
	);
};

export default React.memo(DesktopCollage, arePropsEqual);
