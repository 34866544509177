import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

/** Default styled component for displaying tooltip */
export const TooltipView = ({ id }) => {
	return (
		<Tooltip
			id={id}
			className="bg-black text-white font-roboto font-medium max-w-[95%] z-[10000]"
		/>
	);
};

export default TooltipView;
