import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { firebaseConfig } from "./firebaseConfig";
import firebase from "firebase/compat/app";

firebase.initializeApp(firebaseConfig);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<StrictMode>
		<App />
	</StrictMode>
);
reportWebVitals(sendToVercelAnalytics);
