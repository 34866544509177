import React, { useMemo } from "react";
import useWindowDimensions from "../../../services/utils/Dimensions";
import { IMAGEKIT_ENDPOINT } from "../../../values/constants";

export const MobileCollage = ({
	selectedNfts,
	numOfNftsPerCol,
	numOfNftsPerRow,
	displayWatermark,
	mbbHolder,
	bkgCustomColor,
	displayBorder,
	displayPrices,
	borderWidth,
	borderCustomColor,
	grayscaleOn,
	collageText,
	verticalAlignText,
	horizontalAlignText,
	priceMap,
	setLastActiveId,
	textSize,
	otcPosition,
	otcSize,
}) => {
	const windowDimensions = useWindowDimensions();

	const mobileMaxWidth = useMemo(() => {
		return windowDimensions.width - 20;
	}, [windowDimensions]);

	const mobileImgWidth = useMemo(() => {
		return mobileMaxWidth / numOfNftsPerCol;
	}, [mobileMaxWidth, numOfNftsPerCol]);

	const shrinkage = 150 - otcSize * 1.5;

	const priceStyles = {
		height: (mobileMaxWidth - shrinkage) / numOfNftsPerCol / 4, // height: 1/4 the size of the collage img
		width: (mobileMaxWidth - shrinkage) / numOfNftsPerCol / 1.1, // width: a little bit less than the size of the collage image
		fontSize: (mobileMaxWidth - shrinkage) / numOfNftsPerCol / 6,
		marginTop:
			otcPosition === "bottom" ? mobileMaxWidth / numOfNftsPerCol / 1.5 : 0,
		marginBottom:
			otcPosition === "top" ? mobileMaxWidth / numOfNftsPerCol / 1.5 : 0,
	};

	return (
		<div
			className="flex flex-row flex-wrap items-center text-left w-full mt-2 mb-6 relative"
			id="collage"
			style={{
				width: mobileMaxWidth,
				backgroundColor: bkgCustomColor?.hex ? bkgCustomColor?.hex : "#000",
				minHeight:
					numOfNftsPerRow !== -1 ? mobileImgWidth * numOfNftsPerRow : null,
			}}
		>
			{collageText !== "" && (
				<div
					className={`absolute z-[8] ${
						verticalAlignText === "bottom"
							? "bottom-0"
							: verticalAlignText === "top"
							? "top-0"
							: "top-1/2 -translate-y-1/2"
					} 
				${
					horizontalAlignText === "right"
						? "right-0"
						: horizontalAlignText === "left"
						? "left-0"
						: "left-1/2 -translate-x-1/2"
				} bg-black bg-opacity-50 text-white max-w-full flex-wrap`}
				>
					{/* Text in the bottom right corner */}
					<div className="flex flex-col flex-wrap items-center justify-center mx-2 my-2">
						<p
							className="whitespace-normal"
							style={{ fontSize: textSize + "px" }}
						>
							{collageText}
						</p>
					</div>
				</div>
			)}
			{selectedNfts && selectedNfts.length > 0 ? (
				selectedNfts.map((nft, index) => {
					if (!nft?.image) return null;
					return (
						<div
							className="overflow-hidden flex justify-center items-center"
							style={{
								width: mobileImgWidth,
								height: mobileImgWidth,
							}}
							onClick={() => {
								if (!nft?.image?.includes("collectionLogo=true")) {
									setLastActiveId(nft?.image);
								}
							}}
						>
							<img
								width={mobileImgWidth}
								height={mobileImgWidth}
								crossOrigin="Anonymous"
								className={displayBorder ? "border" : ""}
								style={{
									borderWidth:
										displayBorder && borderWidth ? borderWidth : null,
									borderColor: borderCustomColor?.hex
										? borderCustomColor?.hex
										: "#04004f",
								}}
								src={
									displayWatermark && !mbbHolder && !nft?.image?.includes("gif")
										? IMAGEKIT_ENDPOINT.BASIC_URL +
										  encodeURI(nft?.image) +
										  (encodeURI(nft?.image)?.includes("?") ? "&" : "?") +
										  (grayscaleOn
												? IMAGEKIT_ENDPOINT.WATERMARK_ON_GREYSCALE
												: IMAGEKIT_ENDPOINT.WATERMARK_ON)
										: IMAGEKIT_ENDPOINT.BASIC_URL +
										  (grayscaleOn
												? IMAGEKIT_ENDPOINT.NO_WATERMARK_GREYSCALE
												: IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR) +
										  encodeURI(nft?.image)
								}
								alt={"NFT image #" + index}
								key={index}
								onLoad={(event) => {
									const imgSrc = event.target.src;
									if (
										!imgSrc.includes("i-mbb-watermark") &&
										((displayWatermark &&
											!mbbHolder &&
											!imgSrc?.includes("gif")) ||
											!imgSrc.includes(
												IMAGEKIT_ENDPOINT.BASIC_URL +
													IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR
											))
									) {
										event.target.parentNode.removeChild(event.target);
									}
								}}
							/>

							{nft?.image?.includes("gif") &&
								displayWatermark &&
								!mbbHolder && (
									<img
										crossOrigin="Anonymous"
										src={IMAGEKIT_ENDPOINT.EXTERNAL_FULL_WATERMARK_URL}
										className={`absolute z-100 w-[35px] h-auto opacity-80`}
										onClick={() => {
											if (!nft?.image?.includes("collectionLogo=true")) {
												setLastActiveId(nft?.image);
											}
										}}
									/>
								)}

							{displayPrices === true &&
								!nft?.image?.includes("collectionLogo=true") && (
									<div
										className="absolute z-100 text-center bg-black/60"
										style={priceStyles}
										onClick={() => {
											if (!nft?.image?.includes("collectionLogo=true")) {
												setLastActiveId(nft?.image);
											}
										}}
									>
										<p className="font-roboto font-medium text-white">
											{priceMap?.get(nft?.image)
												? priceMap?.get(nft?.image)
												: "-"}
											{priceMap?.get(nft?.image) &&
												priceMap?.get(nft?.image)?.length < 8 &&
												" SOL"}
										</p>
									</div>
								)}
						</div>
					);
				})
			) : (
				<div className="my-4">
					<p>No NFTs Available</p>
				</div>
			)}
		</div>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const selectedNfts = prevProps?.selectedNfts === nextProps?.selectedNfts;
	const bkgCustomColor =
		prevProps?.bkgCustomColor === nextProps?.bkgCustomColor;
	const numOfNftsPerCol =
		prevProps?.numOfNftsPerCol === nextProps?.numOfNftsPerCol;
	const numOfNftsPerRow =
		prevProps?.numOfNftsPerRow === nextProps?.numOfNftsPerRow;
	const displayBorder = prevProps?.displayBorder === nextProps?.displayBorder;
	const displayPrices = prevProps?.displayPrices === nextProps?.displayPrices;
	const displayWatermark =
		prevProps?.displayWatermark === nextProps?.displayWatermark;
	const mbbHolder = prevProps?.mbbHolder === nextProps?.mbbHolder;
	const borderWidth = prevProps?.borderWidth === nextProps?.borderWidth;
	const borderCustomColor =
		prevProps?.borderCustomColor === nextProps?.borderCustomColor;
	const grayscaleOn = prevProps?.grayscaleOn === nextProps?.grayscaleOn;
	const collageText = prevProps?.collageText === nextProps?.collageText;
	const defaultPriceText =
		prevProps?.defaultPriceText === nextProps?.defaultPriceText;
	const verticalAlignText =
		prevProps?.verticalAlignText === nextProps?.verticalAlignText;
	const horizontalAlignText =
		prevProps?.horizontalAlignText === nextProps?.horizontalAlignText;
	const priceMap = prevProps?.priceMap === nextProps?.priceMap;
	const textSize = prevProps?.textSize === nextProps?.textSize;
	const otcPosition = prevProps?.otcPosition === nextProps?.otcPosition;
	const otcSize = prevProps?.otcSize === nextProps?.otcSize;

	return (
		selectedNfts &&
		numOfNftsPerCol &&
		numOfNftsPerRow &&
		bkgCustomColor &&
		displayBorder &&
		displayPrices &&
		displayWatermark &&
		mbbHolder &&
		borderWidth &&
		borderCustomColor &&
		grayscaleOn &&
		collageText &&
		defaultPriceText &&
		verticalAlignText &&
		horizontalAlignText &&
		priceMap &&
		textSize &&
		otcPosition &&
		otcSize
	);
};

export default React.memo(MobileCollage, arePropsEqual);
