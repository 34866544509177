import React, { useState, useCallback } from "react";
//import useWindowDimensions from "../../../services/utils/Dimensions";

/** Third Party Library - Drag and Drop */
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	DragOverlay,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
} from "@dnd-kit/sortable";

/** Components */
import { ColGrid } from "../col-drag-and-drop/ColGrid";
import { ColItem } from "../col-drag-and-drop/ColItem";
import { ColSortableItem } from "../col-drag-and-drop/ColSortableItem";

export const SortCollections = ({
	selectedCollectionImages,
	setSelectedCollectionImages,
}) => {
	/** ---- Drag and drop functionality on collage ---- */
	const [activeId, setActiveId] = useState(null);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	const handleDragStart = useCallback((event) => {
		setActiveId(event.active.id);
	}, []);
	// Used to move an NFT on the collage
	const handleDragEnd = useCallback((event) => {
		const { active, over } = event;
		// If the NFT is placed on a new spot, update the location in selectedNfts
		if (active.id !== over.id) {
			setSelectedCollectionImages((selectedCollectionImages) => {
				let oldIndex = -1;
				let newIndex = -1;
				for (let index = 0; index < selectedCollectionImages.length; index++) {
					if (active.id.includes(selectedCollectionImages[index].image)) {
						oldIndex = index;
					}
					if (over.id.includes(selectedCollectionImages[index].image)) {
						newIndex = index;
					}
				}
				return arrayMove(selectedCollectionImages, oldIndex, newIndex);
			});
		}
		setActiveId(null);
	}, []);
	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}
		>
			<SortableContext
				items={selectedCollectionImages}
				strategy={rectSortingStrategy}
			>
				<ColGrid columns={4}>
					{selectedCollectionImages.map((col, index) => (
						<ColSortableItem
							key={"sortableitem" + col?.image}
							id={col?.image}
							key={index}
						/>
					))}
				</ColGrid>
			</SortableContext>
			<DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
				{activeId ? <ColItem id={activeId} isDragging /> : null}
			</DragOverlay>
		</DndContext>
	);
};

export default SortCollections;
