import React from "react";

export const ColGrid = ({ children, columns }) => {
	return (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: `repeat(${columns}, 1fr)`,
				gridGap: 12,
			}}
		>
			{children}
		</div>
	);
};

export default ColGrid;
