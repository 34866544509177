import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "../pages/not-found/NotFoundPage";
import CollageGeneratorPage from "../pages/collage-generator/CollageGeneratorPage";

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<CollageGeneratorPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default AppRoutes;
