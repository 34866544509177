import React from "react";

export const NotFoundPage = () => {
	return (
		<div className="pb-24 mt-[87px] lg:mt-[96px]">
			<div className="flex justify-center">
				<p className="pt-8 text-lg font-bold font-roboto text-dark-blue">
					404, Page not Found.
				</p>
			</div>
		</div>
	);
};

export default NotFoundPage;
