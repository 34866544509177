import React from "react";
import { SwatchesPicker } from "react-color";

/** Default component for color picker display (used for borders and background) */
export const ColorPicker = ({ customColor, setCustomColor }) => {
	const handleChange = (customColor) => setCustomColor(customColor);

	return (
		<div className="flex justify-center">
			<SwatchesPicker color={customColor} onChangeComplete={handleChange} />
		</div>
	);
};

export default ColorPicker;
