import React, { useState, useEffect, useRef } from "react";
import { NFT_COLLECTIONS } from "../../values/collections";
import { TooltipView } from "../../components/helpers/TooltipView";

/** Icons */
import { IoIosCheckmarkCircle } from "react-icons/io";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";

export const AvailableCollections = ({
	availableCollections,
	selectCollection,
	selectedCollections,
}) => {
	const [open, setOpen] = useState(true);
	const [sectionHeight, setSectionHeight] = useState(0);
	const sectionRef = useRef();

	useEffect(() => {
		if (!sectionRef.current) return;
		setSectionHeight(open ? sectionRef.current.scrollHeight : 0);
	}, [sectionRef, open]);

	return (
		<div className="flex flex-col text-center justify-center items-center my-2">
			<button
				onClick={() => {
					setOpen(!open);
				}}
				className="mx-4 mb-2 flex flex-row items-center"
			>
				<h3
					className="text-xl font-cubano text-dark-blue"
					style={{ whiteSpace: "pre-line" }}
				>
					2. Select Your Collections
				</h3>
				<a
					data-tooltip-id="info-tooltip"
					data-tooltip-content={
						"Select the NFT collections you would like to add to your collage."
					}
					className="ml-3 lg:ml-2"
				>
					{" "}
					<BsFillInfoCircleFill className="text-dark-gray" size={12} />
				</a>
				{open ? (
					<MdOutlineKeyboardArrowUp
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				) : (
					<MdOutlineKeyboardArrowDown
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				)}
			</button>

			<div
				style={{ maxHeight: sectionHeight + "px", overflow: "hidden" }}
				ref={sectionRef}
				className="transition-all duration-200 flex flex-row justify-center items-start lg:justify-center w-full flex-wrap"
			>
				{NFT_COLLECTIONS &&
					NFT_COLLECTIONS.length > 0 &&
					NFT_COLLECTIONS.map((elem, index) => {
						if (availableCollections[elem.verifiedCreatorAddress] != null) {
							return (
								<div className="mx-2 mb-2" key={index}>
									<button
										onClick={() => {
											selectCollection(
												elem.verifiedCreatorAddress,
												elem.imageUrl
											);
										}}
										key={index}
										className="relative bg-black h-[75px] w-[75px]"
									>
										<img
											width="75"
											height="75"
											className="border border-dark-blue object-cover max-h-[75px]"
											src={elem.imageUrl}
											alt={elem.name + " collection image"}
										/>

										{selectedCollections?.includes(
											elem.verifiedCreatorAddress
										) && (
											<div className={"absolute inset-0	bg-white bg-opacity-70"}>
												<IoIosCheckmarkCircle
													size={48}
													color="#04004f"
													className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
												/>
											</div>
										)}
									</button>
									<p className="text-sm font-roboto font-bold text-dark-blue mt-2 w-[100px]">
										{elem.name.length > 22
											? elem.name.substring(0, 16) + "..."
											: elem.name}{" "}
										(
										{availableCollections[elem.verifiedCreatorAddress]
											? availableCollections[elem.verifiedCreatorAddress]
											: 0}
										)
									</p>
								</div>
							);
						} else {
							return null;
						}
					})}
			</div>
			<TooltipView id="info-tooltip" />
		</div>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const availableCollections =
		prevProps?.availableCollections === nextProps?.availableCollections;
	const selectedCollections =
		prevProps?.selectedCollections === nextProps?.selectedCollections;

	return availableCollections && selectedCollections;
};

export default React.memo(AvailableCollections, arePropsEqual);
