import React, { forwardRef } from "react";

export const ColItem = forwardRef(
	({ id, withOpacity, isDragging, style, ...props }, ref) => {
		const inlineStyles = {
			opacity: withOpacity ? "0.5" : "1",
			transformOrigin: "50% 50%",
			width: "60px",
			height: "60px",
			cursor: isDragging ? "grabbing" : "grab",
			backgroundColor: "#ffffff",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			boxShadow: isDragging
				? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
				: "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
			transform: isDragging ? "scale(1.05)" : "scale(1)",
			overflow: "hidden",
			...style,
		};

		if (!id) return null;

		return (
			<div ref={ref} style={inlineStyles} {...props} className={`block `}>
				<img
					//crossOrigin="Anonymous"
					style={{ display: "block" }}
					src={id}
					alt={"NFT collection"}
					key={"collection" + id}
				/>
			</div>
		);
	}
);

export default ColItem;
