export const COLLECTION_NAMES = {
	MBB_HOLDERS: "mbb_holders",
};

export const DEFAULT_VALUES = {
	DEFAULT_IMAGE:
		"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png",
	MERCHANT_ADDRESS: "CATPa1APxUC7L3KaWkR2Aiy5LYBThRiNQM1MFrTDC9ZC",
	SOL_COLLAGE_PRICE: 0.01,
};

export const SOLANA_RPC_ENDPOINT = {
	METAPLEX: "https://api.metaplex.solana.com/",
	QUICKNODE:
		"https://billowing-skilled-owl.solana-mainnet.discover.quiknode.pro/67ca8c24185da7bb3abdf577ced16267063e69ff/",
	HELIUS: "https://allix-w7mqid-fast-mainnet.helius-rpc.com/",
};

export const IMAGEKIT_ENDPOINT = {
	BASIC_URL: "https://ik.imagekit.io/47y3g0iov/",

	WATERMARK_ON: "tr=w-300,h-300,l-image,i-mbb-watermark.png,l-end", // NOTE: applied as param at end of URL
	WATERMARK_ON_GREYSCALE:
		"tr=w-300,h-300,l-image,i-mbb-watermark.png,l-end,e-grayscale", // NOTE: applied as param at end of URL

	NO_WATERMARK_COLOR: "tr:n-EBfXEmJ8o3/", // NOTE: applied at front of URL
	NO_WATERMARK_GREYSCALE: "tr:n-EBfXEmJ8o3,e-grayscale/", // NOTE: applied at front of URL

	EXTERNAL_FULL_WATERMARK_URL:
		"https://ik.imagekit.io/47y3g0iov/mbb-watermark.png",
};
