import React, { useEffect, useState, useRef } from "react";
import { calculateGridDimensions } from "../../services/grid.service";
import { TooltipView } from "../../components/helpers/TooltipView";

/** Icons */
import { BsFillInfoCircleFill, BsTwitter } from "react-icons/bs";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
	MdSmartphone,
} from "react-icons/md";

export const GridSelection = ({
	selectedNfts,
	numOfNftsPerCol,
	setNumOfNftsPerCol,
	numOfNftsPerRow,
	setNumOfNftsPerRow,
	autoGrid,
	setAutoGrid,
}) => {
	const [open, setOpen] = useState(true);
	const [sectionHeight, setSectionHeight] = useState(0);
	const sectionRef = useRef();

	const maxNumOfNftsPerCol = 23;
	const [gridOptions, setGridOptions] = useState([]);
	const [twitterGridOptions, setTwitterGridOptions] = useState({
		rows: 0,
		cols: 0,
	});
	const [phoneBkgGridOptions, setPhoneBkgGridOptions] = useState({
		rows: 0,
		cols: 0,
	});
	const [prevSelectedNum, setPrevSelectedNum] = useState(0);
	const [customRowNum, setCustomRowNum] = useState("");
	const [customColNum, setCustomColNum] = useState("");

	// Calculates grid options when number of selected NFTs changes
	// or if auto-grid is turned on/off (selects or deselects)
	useEffect(() => {
		calculateGridOptions();
		const { numRows, numCols } = calculateGridDimensions(
			selectedNfts.length,
			3,
			true
		);
		if (numRows > 1) {
			// Ensures the Twitter banner gets filled
			setTwitterGridOptions({ rows: numRows - 1, cols: (numRows - 1) * 3 });
		} else {
			setTwitterGridOptions({ rows: numRows, cols: numCols });
		}

		const { numRows: phoneRows, numCols: phoneCols } = calculateGridDimensions(
			selectedNfts.length,
			16 / 9,
			false
		);
		if (Number(phoneCols.toFixed(0)) > 1) {
			setPhoneBkgGridOptions({
				rows: Number(
					((Number(phoneCols.toFixed(0)) - 1) * (16 / 9)).toFixed(0)
				),
				cols: Number(phoneCols.toFixed(0)) - 1,
			});
		} else {
			setPhoneBkgGridOptions({
				rows: Number(phoneRows.toFixed(0)),
				cols: Number(phoneCols.toFixed(0)),
			});
		}
	}, [selectedNfts, autoGrid]);

	useEffect(() => {
		if (!sectionRef.current) return;
		setSectionHeight(open ? sectionRef.current.scrollHeight : 0);
	}, [sectionRef, open, gridOptions, selectedNfts, customRowNum, customColNum]);

	/** Used to calculate the width and height for the grid size (rows x nums) collage buttons */
	function calculateRatio(selectedVal, otherVal) {
		const sum = selectedVal + otherVal;
		return selectedVal / sum; // selected ratio
	}

	/** Used to calculate the available collage grid options */
	function calculateGridOptions() {
		let gridOptionsGenerated = [];
		for (let numRows = 2; numRows < selectedNfts.length; numRows++) {
			let numCols = Math.ceil(selectedNfts.length / numRows);
			if (
				numRows <= maxNumOfNftsPerCol &&
				numCols <= maxNumOfNftsPerCol &&
				numRows <= numCols
			) {
				gridOptionsGenerated.push({ numRows: numRows, numCols: numCols });
				if (numRows != numCols) {
					gridOptionsGenerated.push({ numRows: numCols, numCols: numRows });
				}
			}
		}
		gridOptionsGenerated.sort((a, b) => a.numRows - b.numRows);
		setGridOptions(gridOptionsGenerated);
		// Only auto-select a grid size if the number of selected NFTs has changed
		if (prevSelectedNum !== selectedNfts.length) {
			autoSelectGrid(gridOptionsGenerated);
		}
	}

	// Used to auto-select the most square / balanced grid option for the collage
	// Only will auto-select grid option when auto:on
	function autoSelectGrid(gridOptionsGenerated) {
		if (autoGrid === true) {
			let best = 100000;
			let bestGridOption = null;
			// When user has multiple grid options, calculate the most-balanced one
			if (gridOptionsGenerated.length > 1) {
				for (let i = 0; i < gridOptionsGenerated.length; i++) {
					const difference =
						gridOptionsGenerated[i].numCols - gridOptionsGenerated[i].numRows;
					if (difference >= 0) {
						if (difference < best) {
							bestGridOption = gridOptionsGenerated[i];
							best = difference;
						}
					}
				}
				if (bestGridOption !== null) {
					setNumOfNftsPerCol(bestGridOption.numCols);
					setNumOfNftsPerRow(-1);
				}
			} else if (gridOptionsGenerated.length === 1) {
				// When user only has 1 grid option.
				setNumOfNftsPerCol(gridOptionsGenerated[0].numCols);
				setNumOfNftsPerRow(-1);
			} else if (selectedNfts.length >= 1) {
				// When user has 1-2 NFTs
				setNumOfNftsPerCol(selectedNfts.length);
				setNumOfNftsPerRow(-1);
			}
		}
		// else if (autoGrid === false) {
		// 	setNumOfNftsPerCol(-1);
		// 	setNumOfNftsPerRow(-1);
		// }
		setPrevSelectedNum(selectedNfts.length);
	}

	// When the user types in text, change collage text state
	function handleTextChange(e, setText) {
		setText(e.target.value);
	}

	return (
		<div className="flex flex-col text-center justify-center items-center my-2">
			<button
				onClick={() => {
					setOpen(!open);
				}}
				className="mx-4 mb-2 flex flex-row justify-center items-center "
			>
				<h3
					className="text-xl font-cubano text-dark-blue"
					style={{ whiteSpace: "pre-line" }}
				>
					4. Choose a Grid Size
				</h3>
				<a
					data-tooltip-id="info-tooltip"
					data-tooltip-content={
						"Choose the desired grid size of your collage (rows x columns)."
					}
					className="ml-3 lg:ml-2"
				>
					{" "}
					<BsFillInfoCircleFill className="text-dark-gray" size={12} />
				</a>
				{open ? (
					<MdOutlineKeyboardArrowUp
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				) : (
					<MdOutlineKeyboardArrowDown
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				)}
			</button>

			<div
				className="transition-all duration-200"
				style={{ maxHeight: sectionHeight + "px", overflow: "hidden" }}
				ref={sectionRef}
			>
				{selectedNfts && selectedNfts.length > 0 ? (
					<>
						<div className="flex flex-row flex-wrap items-center justify-center my-2">
							<button
								onClick={() => {
									setAutoGrid(!autoGrid);
								}}
								className={
									"rounded-xl py-2 px-4 mr-4 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
								}
							>
								{autoGrid ? "Auto: On" : "Auto: Off"}
							</button>

							<input
								className="flex max-w-[200px] rounded-xl py-2 px-4 mr-4 border-2 border-dark-grey border-opacity-30
					 focus:outline-pink	bg-white text-dark-gray font-cubano text-sm"
								placeholder="Enter Custom Row"
								value={customRowNum}
								onChange={(e) => {
									handleTextChange(e, setCustomRowNum);

									if (
										!isNaN(Number(e.target.value)) &&
										!isNaN(Number(customColNum)) &&
										customColNum !== "" &&
										e.target.value !== ""
									) {
										setAutoGrid(false);
										setNumOfNftsPerCol(Number(customColNum));
										setNumOfNftsPerRow(Number(e.target.value));
									}
								}}
							/>

							<input
								className="flex max-w-[200px] rounded-xl py-2 px-4 border-2 border-dark-grey border-opacity-30
					 focus:outline-pink	bg-white text-dark-gray font-cubano text-sm"
								placeholder="Enter Custom Column"
								value={customColNum}
								onChange={(e) => {
									handleTextChange(e, setCustomColNum);

									if (
										!isNaN(Number(e.target.value)) &&
										!isNaN(Number(customRowNum)) &&
										customRowNum !== "" &&
										e.target.value !== ""
									) {
										setAutoGrid(false);
										setNumOfNftsPerCol(Number(e.target.value));
										setNumOfNftsPerRow(Number(customRowNum));
									}
								}}
							/>
						</div>

						<div className="pb-2 px-4 flex flex-row items-center justify-center flex-wrap w-full max-w-4xl">
							{selectedNfts.length <= 10 && (
								<button
									onClick={() => {
										setNumOfNftsPerCol(selectedNfts.length);
										setNumOfNftsPerRow(-1);
									}}
									className={`${
										numOfNftsPerCol == selectedNfts.length &&
										numOfNftsPerRow === -1
											? "border-4"
											: "border-2 border-opacity-30"
									} block rounded-xl mx-2 my-1 py-2 px-4 truncate border-pink	 bg-white text-dark-gray font-cubano`}
									style={{
										width: 200,
										height: 50,
									}}
								>
									1x{selectedNfts.length}
								</button>
							)}
							{gridOptions &&
								gridOptions.map((elem, index) => (
									<button
										onClick={() => {
											setNumOfNftsPerCol(elem.numCols);
											setNumOfNftsPerRow(-1);
										}}
										className={`${
											numOfNftsPerCol === elem.numCols && numOfNftsPerRow === -1
												? "border-4"
												: "border-2 border-opacity-30"
										} block rounded-xl m-2 px-4 truncate border-pink bg-white text-dark-gray text-md font-cubano`}
										style={{
											minWidth: 85,
											minHeight: 45,
											width: calculateRatio(elem.numCols, elem.numRows) * 155,
											height: calculateRatio(elem.numRows, elem.numCols) * 155,
										}}
										key={index}
									>
										{elem.numRows}x{elem.numCols}
									</button>
								))}
							<button
								onClick={() => {
									setNumOfNftsPerCol(twitterGridOptions.cols);
									setNumOfNftsPerRow(twitterGridOptions.rows);
								}}
								className={`${
									numOfNftsPerCol == twitterGridOptions.cols &&
									numOfNftsPerRow !== -1
										? "border-4"
										: "border-2 border-opacity-30"
								} block rounded-xl mx-2 my-1 py-2 px-4 truncate border-pink	 bg-white text-dark-gray font-cubano`}
								style={{
									width: 200,
									height: 50,
								}}
							>
								<div className="flex flex-row justify-center items-center">
									<BsTwitter className="text-dark-gray mr-2" size={14} />
									Banner: {twitterGridOptions.rows}x{twitterGridOptions.cols}
								</div>
							</button>

							<button
								onClick={() => {
									setNumOfNftsPerCol(phoneBkgGridOptions.cols);
									setNumOfNftsPerRow(phoneBkgGridOptions.rows);
								}}
								className={`${
									numOfNftsPerCol == phoneBkgGridOptions.cols &&
									numOfNftsPerRow === phoneBkgGridOptions.rows
										? "border-4"
										: "border-2 border-opacity-30"
								} block rounded-xl mx-2 my-1 py-2 px-4 truncate border-pink	 bg-white text-dark-gray font-cubano`}
								style={{
									width: 200,
									height: 75,
								}}
							>
								<div className="flex flex-row justify-center items-center">
									<MdSmartphone className="text-dark-gray mr-1" size={18} />
									Bkg: {phoneBkgGridOptions.rows}x{phoneBkgGridOptions.cols}
								</div>
							</button>

							{(customRowNum != "" || customColNum != "") && (
								<button
									disabled={
										isNaN(Number(customRowNum)) ||
										isNaN(Number(customColNum)) ||
										Number(customRowNum) <= 0 ||
										Number(customColNum) <= 0
									}
									onClick={() => {
										setAutoGrid(false);
										setNumOfNftsPerCol(Number(customColNum));
										setNumOfNftsPerRow(Number(customRowNum));
									}}
									className={`${
										numOfNftsPerCol === Number(customColNum) &&
										numOfNftsPerRow === Number(customRowNum)
											? "border-4"
											: "border-2 border-opacity-30"
									} block rounded-xl mx-2 my-1 py-2 px-4 truncate border-pink	 bg-white text-dark-gray font-cubano
								disabled:opacity-50 disabled:border-gray-200`}
									style={{
										width: 200,
										height: 75,
									}}
								>
									<div className="flex flex-row justify-center items-center">
										Custom Grid: {customRowNum}x{customColNum}
									</div>
								</button>
							)}
						</div>
					</>
				) : (
					<div className="my-4">
						<p>Select some NFT(s) above to view grid options.</p>
					</div>
				)}
			</div>
			<TooltipView id="info-tooltip" />
		</div>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const selectedNfts = prevProps?.selectedNfts === nextProps?.selectedNfts;
	const numOfNftsPerCol =
		prevProps?.numOfNftsPerCol === nextProps?.numOfNftsPerCol;
	const numOfNftsPerRow =
		prevProps?.numOfNftsPerRow === nextProps?.numOfNftsPerRow;

	const autoGrid = prevProps?.autoGrid === nextProps?.autoGrid;

	return selectedNfts && numOfNftsPerCol && numOfNftsPerRow && autoGrid;
};

export default React.memo(GridSelection, arePropsEqual);
