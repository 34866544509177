import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Item from "./Item";

export const SortableItem = (props) => {
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: props.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined,
	};

	return (
		<Item
			ref={setNodeRef}
			style={style}
			withOpacity={isDragging}
			{...props}
			{...attributes}
			{...listeners}
			numOfNftsPerCol={props.numOfNftsPerCol}
			maxCollageWidth={props.maxCollageWidth}
		/>
	);
};

export default SortableItem;
