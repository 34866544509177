import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import useWindowDimensions from "../../services/utils/Dimensions";
import { Oval, Puff } from "react-loader-spinner";
import { NFT_COLLECTIONS } from "../../values/collections";

import { TooltipView } from "../../components/helpers/TooltipView";

import { DEFAULT_VALUES, IMAGEKIT_ENDPOINT } from "../../values/constants";
// import { watermarkIssueFlag } from "../../services/utils/WatermarkCheck";

/** Icons */
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";
import cloneDeep from "lodash.clonedeep";
import SortCollections from "./SortCollections";

export const AvailableNFTs = ({
	loadingNfts,
	traitNames,
	traitTypes,
	selectFilter,
	filteredTraits,
	viewableNfts,
	setViewableNfts,
	selectedNfts,
	allNFTs,
	selectAnNft,
	selectAll,
	setNumOfNftsPerCol,
	setNumOfNftsPerRow,
	clearFilteredTraits,
	selectedCollections,
	setTraitTypes,
	setTraitNames,
	traitsAll,
	selectedDropdownCollection,
	setSelectedDropdownCollection,
	viewCollectionLogos,
	toggleCollectionLogos,
	selectedCollectionImages,
	selectAnCollectionImg,
	displayWatermark,
	mbbHolder,
	deselectAll,
	setFilteredTraits,
	maxSelectedNftsMsg,
}) => {
	const windowDimensions = useWindowDimensions();
	const [collectionOptions, setCollectionOptions] = useState([]);
	const [unverifiedSearchQuery, setUnverifiedSearchQuery] = useState("");

	const [open, setOpen] = useState(true);
	const [sectionHeight, setSectionHeight] = useState(0);
	const sectionRef = useRef();

	// When the user types in text, changes search query for unverified nfts
	function handleTextChange(e) {
		setUnverifiedSearchQuery(e.target.value);
	}

	// Used to search unverified NFTs, also clears all filtered traits
	useEffect(() => {
		setFilteredTraits({});
		const newNftsList = [];
		for (let i = 0; i < allNFTs.length; i++) {
			if (selectedCollections.includes(allNFTs[i].verifiedCreatorAddress)) {
				newNftsList.push(allNFTs[i]);
			}
		}
		setViewableNfts(
			newNftsList.filter((nft) =>
				nft.name.toLowerCase().includes(unverifiedSearchQuery.toLowerCase())
			)
		);
	}, [unverifiedSearchQuery]);

	useEffect(() => {
		if (!sectionRef.current) return;
		setSectionHeight(open ? sectionRef.current.scrollHeight : 0);
	}, [
		sectionRef,
		open,
		selectedDropdownCollection,
		filteredTraits,
		viewableNfts,
		selectedNfts,
	]);

	/** Updates the available collection options when the user changes the selected collections */
	useEffect(() => {
		const options = [];
		for (let i = 0; i < NFT_COLLECTIONS.length; i++) {
			if (
				selectedCollections.includes(
					NFT_COLLECTIONS[i].verifiedCreatorAddress
				) &&
				NFT_COLLECTIONS[i].verifiedCreatorAddress !== "other"
			) {
				options.push(NFT_COLLECTIONS[i]);
			}
		}
		setCollectionOptions(options);

		// Checks if the user has deselected the collection that was active for the dropdown filters
		// If so, it changes the dropdown collections and traits to an active collection or null
		if (selectedDropdownCollection !== null) {
			let dropdownIsValid = false;
			for (let i = 0; i < options.length; i++) {
				if (
					options[i].verifiedCreatorAddress == selectedDropdownCollection?.value
				) {
					dropdownIsValid = true;
					break;
				}
			}
			if (!dropdownIsValid) {
				if (options.length > 0) {
					const newCollection = {
						label: options[0].name,
						value: options[0].verifiedCreatorAddress,
						key: 0,
					};
					if (newCollection !== null) {
						selectCollectionFilter(newCollection);
					}
				} else {
					setSelectedDropdownCollection(null);
					setTraitNames([]);
					setTraitTypes([]);
				}
			}
		}
	}, [selectedCollections]);

	/** Sets the default selected collection to filter by if none are selected */
	useEffect(() => {
		// return;
		if (
			selectedDropdownCollection === null &&
			collectionOptions !== null &&
			collectionOptions?.length > 0
		) {
			for (let k = 0; k < collectionOptions.length; k++) {
				if (traitsAll[collectionOptions[k]?.verifiedCreatorAddress]) {
					if (
						collectionOptions[k]?.verifiedCreatorAddress &&
						collectionOptions[k]?.verifiedCreatorAddress !== "other" &&
						Object.keys(
							traitsAll[collectionOptions[k].verifiedCreatorAddress]
						) !== null
					) {
						selectCollectionFilter({
							label: collectionOptions[k].name,
							value: collectionOptions[k].verifiedCreatorAddress,
							key: 0,
						});
						break;
					}
				}
			}
		}
	}, [traitsAll, collectionOptions]);

	/** Used to select a collection to filter NFTs by traits */
	function selectCollectionFilter(collection) {
		if (collection && collection.value && collection.value !== "other") {
			setSelectedDropdownCollection(collection);
			sortViewableNfts(collection);
			try {
				setTraitTypes(traitsAll[collection.value]);
				setTraitNames(Object.keys(traitsAll[collection.value]));
			} catch {
				setTraitTypes([]);
				setTraitNames([]);
			}
		}
	}

	/** Sorts the viewable NFTs to have the selected dropdown collection at the top */
	function sortViewableNfts(selectedDropdownCollection) {
		const sortedNfts = [];
		for (let i = 0; i < viewableNfts.length; i++) {
			if (
				selectedDropdownCollection.value ===
				viewableNfts[i].verifiedCreatorAddress
			) {
				sortedNfts.push(viewableNfts[i]);
			}
		}

		// Loop through all viewableNfts not in the dropdown and add
		for (let i = 0; i < viewableNfts.length; i++) {
			// If the viewable NFT doesn't belong to the NFT collection that is currently being
			// trait filtered, just leave it
			if (
				selectedDropdownCollection.value !==
				viewableNfts[i].verifiedCreatorAddress
			) {
				sortedNfts.push(viewableNfts[i]);
			}
		}

		setViewableNfts(sortedNfts);
	}

	return (
		<div className="flex flex-col text-center justify-center items-center my-2">
			<button
				onClick={() => {
					setOpen(!open);
				}}
				className="mx-4 mb-2 flex flex-row items-center"
			>
				<h3
					className="text-xl font-cubano text-dark-blue"
					style={{ whiteSpace: "pre-line" }}
				>
					3. Select Your NFTs
				</h3>
				<a
					data-tooltip-id="info-tooltip"
					data-tooltip-content={
						"Select the desired NFTs you would like to add to your collage."
					}
					className="ml-3 lg:ml-2"
				>
					{" "}
					<BsFillInfoCircleFill className="text-dark-gray" size={12} />
				</a>
				{open ? (
					<MdOutlineKeyboardArrowUp
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				) : (
					<MdOutlineKeyboardArrowDown
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				)}
			</button>

			<div
				className="transition-all duration-200"
				style={{ maxHeight: sectionHeight + "px", overflow: "hidden" }}
				ref={sectionRef}
			>
				<div className="flex flex-col items-center justify-center">
					<p className="text-md mb-4 font-roboto font-semibold text-dark-blue">
						Filter a Collection's NFTs based on Traits
					</p>
					<Select
						closeMenuOnSelect={true}
						onChange={(e) => {
							selectCollectionFilter(e);
						}}
						options={collectionOptions.map((col, index) => {
							return {
								label: col.name,
								value: col.verifiedCreatorAddress,
								key: index,
							};
						})}
						value={selectedDropdownCollection ? selectedDropdownCollection : ""}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								minWidth: "300px",
								maxWidth: windowDimensions.width > 768 ? "450px" : null,
							}),
						}}
					/>
				</div>

				{!loadingNfts && (
					<div className="flex flex-row items-center justify-center mt-2 mb-4">
						<div className="flex flex-row items-center justify-center md:justify-between max-w-xl md:max-w-[750px] flex-wrap">
							{traitNames &&
								traitNames.length > 0 &&
								traitNames.map((name, index) => {
									return (
										<div
											className="flex flex-row items-center justify-between p-2 w-[350px]"
											key={index}
										>
											<p className="text-md font-cubano font-bold text-dark-blue mx-4">
												{name}
											</p>

											{traitTypes?.[name] && (
												<>
													<Select
														closeMenuOnSelect={false}
														onChange={(e) => {
															selectFilter(e, name, selectedDropdownCollection);
														}}
														isMulti
														options={traitTypes[name].map((trait, index) => {
															return {
																label: trait,
																value: trait,
																key: index,
															};
														})}
														value={
															filteredTraits?.[
																selectedDropdownCollection?.value
															]?.[name]
																? filteredTraits[
																		selectedDropdownCollection.value
																  ][name].map((trait, index) => {
																		return {
																			label: trait,
																			value: trait,
																			key: index,
																		};
																  })
																: []
														}
														styles={{
															control: (baseStyles) => ({
																...baseStyles,
																width: "200px",
															}),
														}}
													/>
												</>
											)}
										</div>
									);
								})}
						</div>
					</div>
				)}

				{viewableNfts?.length > 0 && !loadingNfts && (
					<>
						<button
							onClick={() => {
								selectAll();
							}}
							className={
								"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
							}
						>
							Select All
						</button>
						<button
							onClick={() => {
								deselectAll();
							}}
							className={
								"rounded-xl py-2 px-4 mr-4 mb-2 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
							}
						>
							Deselect All
						</button>
					</>
				)}
				{allNFTs?.length > 0 && !loadingNfts && (
					<>
						<button
							onClick={() => {
								clearFilteredTraits();
							}}
							className={
								"rounded-xl py-2 px-4 mb-2 mr-4 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
							}
						>
							Clear Filters
						</button>

						<button
							onClick={() => {
								toggleCollectionLogos();
							}}
							className={
								"rounded-xl py-2 px-4 mb-2 mr-4 border-2 border-dark-grey border-opacity-30	bg-white text-dark-gray font-cubano text-sm"
							}
						>
							Collection Logos: {viewCollectionLogos ? "On" : "Off"}
						</button>
					</>
				)}

				<div className="flex flex-row items-center justify-center mt-1 mb-1">
					<p className="text-md mr-2 font-roboto font-semibold text-dark-blue">
						Why are there watermarks on the images?
					</p>
					<a
						data-tooltip-id="watermark-filter-tooltip"
						data-tooltip-content={
							"The watermarks are temporary and will disappear after you purchase your collage. FREE for MBB Holders."
						}
					>
						<BsFillInfoCircleFill className="text-dark-gray" size={12} />
					</a>
				</div>

				<p className="text-md mr-2 font-roboto font-medium text-pink mb-2">
					Note: Images may take a few seconds to load in.
				</p>
				{maxSelectedNftsMsg !== "" && (
					<p className="text-md mr-2 font-roboto font-medium text-pink mb-2">
						{maxSelectedNftsMsg}
					</p>
				)}

				{selectedCollections && selectedCollections?.includes("other") && (
					<div className="flex flex-row items-center justify-center mb-4">
						<p className="text-md mr-2 font-roboto font-semibold text-dark-blue">
							Unverified NFTs:
						</p>
						<input
							className="max-w-[300px] rounded-xl py-2 px-4  border-2 border-dark-grey border-opacity-30
					 focus:outline-pink	bg-white text-dark-gray font-cubano text-sm"
							placeholder="Search Unverified NFTs"
							value={unverifiedSearchQuery}
							onChange={(e) => {
								handleTextChange(e);
							}}
						/>
					</div>
				)}

				{loadingNfts ? (
					<div className="flex flex-row items-center justify-center w-full mt-6">
						<Oval
							height={40}
							width={40}
							color="#e41e8e"
							wrapperStyle={{ display: "flex", justifyContent: "center" }}
							visible={true}
							ariaLabel="oval-loading"
							secondaryColor="#e41e8e"
							strokeWidth={2}
							strokeWidthSecondary={2}
						/>
					</div>
				) : (
					<div className="flex flex-row items-center justify-center mt-2 text-left flex-wrap w-full max-w-3xl">
						{viewCollectionLogos &&
							selectedCollectionImages?.length > 0 &&
							selectedCollectionImages.map((colImg, index) => {
								return (
									<button
										onClick={() => selectAnCollectionImg(colImg)}
										key={index}
										className="relative"
									>
										<div className="w-[90px] h-[90px] overflow-hidden flex justify-center items-center">
											<img
												className="border border-dark-blue"
												crossOrigin="Anonymous"
												src={
													displayWatermark &&
													!mbbHolder &&
													!colImg?.image?.includes("gif")
														? IMAGEKIT_ENDPOINT.BASIC_URL +
														  encodeURI(
																colImg?.image
																	? colImg?.image
																	: DEFAULT_VALUES.DEFAULT_IMAGE
														  ) +
														  (encodeURI(colImg?.image)?.includes("?")
																? "&"
																: "?") +
														  IMAGEKIT_ENDPOINT.WATERMARK_ON
														: IMAGEKIT_ENDPOINT.BASIC_URL +
														  IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR +
														  encodeURI(
																colImg?.image
																	? colImg?.image
																	: DEFAULT_VALUES.DEFAULT_IMAGE
														  )
												}
												onLoad={(event) => {
													const imgSrc = event.target.src;
													if (
														!imgSrc.includes("i-mbb-watermark") &&
														((displayWatermark &&
															!mbbHolder &&
															!imgSrc?.includes("gif")) ||
															!imgSrc.includes(
																IMAGEKIT_ENDPOINT.BASIC_URL +
																	IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR
															))
													) {
														event.target.parentNode.removeChild(event.target);
													}
												}}
												alt={"Collection image"}
											/>
										</div>
										{selectedNfts
											.map((elem) => elem.image)
											?.includes(colImg?.image) && (
											<div className={"absolute inset-0	bg-white bg-opacity-70"}>
												<h1 className="text-5xl font-cubano text-dark-blue absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
													{selectedNfts
														.map((elem) => elem.image)
														?.indexOf(colImg?.image) + 1}
												</h1>
											</div>
										)}
									</button>
								);
							})}

						{viewableNfts && viewableNfts.length > 0 ? (
							viewableNfts.map((nft, index) => {
								if (!nft?.collectionDetails?.image) return null;
								return (
									<button
										onClick={() => selectAnNft(nft)}
										key={index}
										className="relative"
									>
										<div className="w-[90px] h-[90px] overflow-hidden flex justify-center items-center">
											{nft?.collectionDetails?.image != null &&
												nft?.collectionDetails?.image != "" && (
													<div id={`loading-${nft?.collectionDetails?.image}`}>
														<Puff
															height={30}
															width={30}
															color="#e41e8e"
															wrapperStyle={{
																display: "flex",
																justifyContent: "center",
															}}
															visible={true}
															ariaLabel="puff-loading"
														/>
													</div>
												)}

											<img
												className="border border-dark-blue hidden"
												crossOrigin="Anonymous"
												src={
													displayWatermark &&
													!mbbHolder &&
													!nft?.collectionDetails?.image?.includes("gif")
														? IMAGEKIT_ENDPOINT.BASIC_URL +
														  encodeURI(nft?.collectionDetails?.image) +
														  (encodeURI(
																nft?.collectionDetails?.image
														  )?.includes("?")
																? "&"
																: "?") +
														  "tr=w-300,h-300,l-image,i-mbb-watermark.png,l-end"
														: IMAGEKIT_ENDPOINT.BASIC_URL +
														  IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR +
														  encodeURI(nft?.collectionDetails?.image)
												}
												alt={nft?.name + " NFT image"}
												onError={() => {
													try {
														const loader = document.getElementById(
															`loading-${nft?.collectionDetails?.image}`
														);
														loader.parentNode.removeChild(loader);
													} catch {
														return;
													}
												}}
												onLoad={(event) => {
													event.target.style.display = "block";
													try {
														const loader = document.getElementById(
															`loading-${nft?.collectionDetails?.image}`
														);
														loader.parentNode.removeChild(loader);
													} catch {
														return;
													}

													const imgSrc = event.target.src;
													if (
														!imgSrc.includes("i-mbb-watermark") &&
														((displayWatermark &&
															!mbbHolder &&
															!imgSrc?.includes("gif")) ||
															!imgSrc.includes(
																IMAGEKIT_ENDPOINT.BASIC_URL +
																	IMAGEKIT_ENDPOINT.NO_WATERMARK_COLOR
															))
													) {
														event.target.parentNode.removeChild(event.target);
													}
												}}
											/>

											{nft?.collectionDetails?.image?.includes("gif") &&
												displayWatermark &&
												!mbbHolder && (
													<img
														crossOrigin="Anonymous"
														src={IMAGEKIT_ENDPOINT.EXTERNAL_FULL_WATERMARK_URL}
														className={`absolute z-2 w-[80px] h-auto opacity-80`}
													/>
												)}
										</div>
										{selectedNfts
											.map((elem) => elem.image)
											?.includes(nft?.collectionDetails?.image) && (
											<div className={"absolute inset-0	bg-white bg-opacity-70"}>
												<h1 className="text-5xl font-cubano text-dark-blue absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
													{selectedNfts
														.map((elem) => elem.image)
														?.indexOf(nft?.collectionDetails?.image) + 1}
												</h1>
											</div>
										)}
									</button>
								);
							})
						) : (
							<div className="my-4">
								<p>No NFTs Available</p>
							</div>
						)}
					</div>
				)}
			</div>
			<TooltipView
				id="watermark-filter-tooltip"
				className="bg-black text-white font-roboto font-medium max-w-[95%]"
			/>
			<TooltipView
				id="info-tooltip"
				className="bg-black text-white font-roboto font-medium max-w-[95%]"
			/>
		</div>
	);
};
export default AvailableNFTs;
