import React, { useState, useEffect, useRef } from "react";
import RemoveIcon from "../../assets/images/remove-wallet-icon.png";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { TooltipView } from "../../components/helpers/TooltipView";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

export const WalletInput = ({
	walletList,
	setWalletList,
	generateNfts,
	errorWalletInputMsg,
	restartCollage,
	loadingCollections,
	totalNFTsFetching,
}) => {
	const [maxWalletsMsg, setMaxWalletsMsg] = useState(false);
	const [open, setOpen] = useState(true);
	const [sectionHeight, setSectionHeight] = useState(0);
	const sectionRef = useRef();

	useEffect(() => {
		if (!sectionRef.current) return;
		setSectionHeight(open ? sectionRef.current.scrollHeight : 0);
	}, [sectionRef, open, walletList, errorWalletInputMsg, maxWalletsMsg]);

	// Ensures the user has inputted less than 7 wallets
	useEffect(() => {
		if (walletList.length < 7) {
			setMaxWalletsMsg(false);
		} else {
			setMaxWalletsMsg(true);
		}
	}, [walletList]);

	// Adds a new wallet input field
	function addWallet() {
		if (walletList.length < 7) {
			setWalletList([...walletList, " "]);
		} else {
			setMaxWalletsMsg(true);
		}
	}

	// Removes a wallet input field
	const removeWallet = (index) => {
		setWalletList(walletList.filter((w, i) => i !== index));
		setMaxWalletsMsg(false);
	};

	// When the user types in a wallet address, update the walletList state
	function handleWalletTextChange(e, index) {
		const nextWallet = walletList.map((w, i) => {
			if (i === index) {
				// Adjust the wallet that the user is typing in
				return e.target.value;
			} else {
				return w;
			}
		});
		setWalletList(nextWallet);
	}

	return (
		<div className="flex flex-col text-center justify-center items-center my-2">
			<button
				onClick={() => {
					setOpen(!open);
				}}
				className="mx-4 flex flex-row mb-2 items-center"
			>
				<h3
					className="text-xl font-cubano text-dark-blue"
					style={{ whiteSpace: "pre-line" }}
				>
					1. Paste in Wallet Addresses
				</h3>
				<a
					data-tooltip-id="info-tooltip"
					data-tooltip-content={
						"Paste in any SOL wallet addresses that have NFTs you want to add to your collage."
					}
					className="ml-3 lg:ml-2"
				>
					{" "}
					{/**color="#818181" */}
					<BsFillInfoCircleFill className="text-dark-gray" size={12} />
				</a>
				{open ? (
					<MdOutlineKeyboardArrowUp
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				) : (
					<MdOutlineKeyboardArrowDown
						size={36}
						color="#04004f"
						className="ml-1"
					/>
				)}
			</button>

			<div
				className="transition-all duration-200"
				style={{ maxHeight: sectionHeight + "px", overflow: "hidden" }}
				ref={sectionRef}
			>
				<div className="flex flex-col">
					{walletList &&
						walletList.map((wallet, index) => (
							<div key={index} className="flex flex-row items-center mx-4">
								<input
									className="flex flex-grow border-2 rounded-lg px-4 py-2 my-2 font-extrabold font-sans text-dark-gray focus:outline-pink	"
									placeholder="Paste Here"
									value={wallet}
									onChange={(e) => {
										handleWalletTextChange(e, index);
									}}
								/>
								{(index != 0 || walletList.length > 1) && (
									<button
										onClick={() => {
											removeWallet(index);
										}}
										className="ml-2"
									>
										<img
											src={RemoveIcon}
											width={40}
											height={40}
											alt="Remove wallet icon"
										/>
									</button>
								)}
							</div>
						))}
				</div>

				<div className="px-4 flex flex-row justify-center flex-wrap">
					<button
						onClick={() => {
							restartCollage();
						}}
						className={
							"block rounded-xl py-2 px-4 my-2 mr-4 truncate border-2 border-dark-grey border-opacity-30 bg-white text-dark-gray font-cubano"
						}
					>
						Restart
					</button>
					<button
						onClick={() => {
							addWallet();
						}}
						className="block rounded-xl py-2 px-4 my-2 mr-4 truncate border border-navy-blue bg-navy-blue text-white font-cubano"
					>
						Add Another Wallet
					</button>
					<button
						onClick={() => {
							generateNfts();
						}}
						disabled={
							(walletList.length == 1 && walletList[0] == "") ||
							loadingCollections
						}
						className="block rounded-xl py-2 px-4 my-2 truncate border border-pink bg-pink text-white font-cubano disabled:opacity-50"
					>
						{!loadingCollections ? "Generate" : "Loading..."}
					</button>
				</div>

				{errorWalletInputMsg != "" && (
					<p className="text-md font-cubano text-pink">{errorWalletInputMsg}</p>
				)}
				{maxWalletsMsg && (
					<p className="text-md font-cubano text-pink">
						Note: Max of 7 wallets can be added.
					</p>
				)}
			</div>

			{loadingCollections && (
				<div className="flex flex-col items-center justify-center w-full mt-4">
					<Oval
						height={40}
						width={40}
						color="#e41e8e"
						wrapperStyle={{ display: "flex", justifyContent: "center" }}
						visible={true}
						ariaLabel="oval-loading"
						secondaryColor="#e41e8e"
						strokeWidth={2}
						strokeWidthSecondary={2}
					/>
					{totalNFTsFetching != 0 && (
						<p className="font-cubano text-pink pt-4 pb-2">
							Fetching {totalNFTsFetching} NFTs...
						</p>
					)}
					<p className="font-cubano text-pink pb-4">
						Please be patient. This may take a few moments...
					</p>
				</div>
			)}

			<TooltipView id="info-tooltip" />
		</div>
	);
};

/*
 * Check specific props to determine
 * if component will re-render
 */
const arePropsEqual = (prevProps, nextProps) => {
	const walletList = prevProps?.walletList === nextProps?.walletList;
	const totalNFTsFetching =
		prevProps?.totalNFTsFetching === nextProps?.totalNFTsFetching;
	const errorWalletInputMsg =
		prevProps?.errorWalletInputMsg === nextProps?.errorWalletInputMsg;
	const loadingCollections =
		prevProps?.loadingCollections === nextProps?.loadingCollections;

	return (
		walletList && errorWalletInputMsg && loadingCollections && totalNFTsFetching
	);
};

export default React.memo(WalletInput, arePropsEqual);
